@font-face {
  font-family: 'fb_meeting_icons';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_meeting_icons.eot?337wuz');
  src: url('fonts/fb_meeting_icons.eot?337wuz#iefix') format('embedded-opentype'),
  url('fonts/fb_meeting_icons.woff2?337wuz') format('woff2'),
  url('fonts/fb_meeting_icons.woff?337wuz') format('woff'),
  url('fonts/fb_meeting_icons.ttf?337wuz') format('truetype'),
  url('fonts/fb_meeting_icons.svg?337wuz#fb_meeting_icons') format('svg');
  font-display: block;
}

[class^="fb_meeting_"], [class*=" fb_meeting_"] {
  font-family: 'fb_meeting_icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}