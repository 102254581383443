.fb_energy_ {
  &temperature_high:before {
    content: "\ae00";
  }
  &atom:before {
    content: "\ae01";
  }
  &barrel:before {
    content: "\ae02";
  }
  &battery:before {
    content: "\ae03";
  }
  &battery_car:before {
    content: "\ae04";
  }
  &battery_charging:before {
    content: "\ae05";
  }
  &brain:before {
    content: "\ae06";
  }
  &car_charging_plug:before {
    content: "\ae07";
  }
  &car_charging_station:before {
    content: "\ae08";
  }
  &coal:before {
    content: "\ae09";
  }
  &cogs:before {
    content: "\ae0a";
  }
  &electric_car:before {
    content: "\ae0b";
  }
  &electricity:before {
    content: "\ae0c";
  }
  &factory:before {
    content: "\ae0d";
  }
  &fire:before {
    content: "\ae0e";
  }
  &fuel:before {
    content: "\ae0f";
  }
  &gas_mask:before {
    content: "\ae10";
  }
  &green_power:before {
    content: "\ae11";
  }
  &hammer:before {
    content: "\ae12";
  }
  &hand_leaf:before {
    content: "\ae13";
  }
  &hydroelectric_power_mill:before {
    content: "\ae14";
  }
  &hydroelectric_power_plant:before {
    content: "\ae15";
  }
  &lab:before {
    content: "\ae16";
  }
  &lamp:before {
    content: "\ae17";
  }
  &leaf:before {
    content: "\ae18";
  }
  &light_bulb:before {
    content: "\ae19";
  }
  &mill:before {
    content: "\ae1a";
  }
  &nuclear:before {
    content: "\ae1b";
  }
  &nuclear_power_plant:before {
    content: "\ae1c";
  }
  &oil:before {
    content: "\ae1d";
  }
  &oil_derrick:before {
    content: "\ae1e";
  }
  &oil_pump:before {
    content: "\ae1f";
  }
  &oil_station:before {
    content: "\ae20";
  }
  &pipe:before {
    content: "\ae21";
  }
  &power_cord:before {
    content: "\ae22";
  }
  &power_grid:before {
    content: "\ae23";
  }
  &power_plant:before {
    content: "\ae24";
  }
  &recycle:before {
    content: "\ae25";
  }
  &road:before {
    content: "\ae26";
  }
  &skull:before {
    content: "\ae27";
  }
  &solar_panel:before {
    content: "\ae28";
  }
  &sun:before {
    content: "\ae29";
  }
  &temperature_low:before {
    content: "\ae2a";
  }
  &thermometer:before {
    content: "\ae2b";
  }
  &trash:before {
    content: "\ae2c";
  }
  &wind_turbine:before {
    content: "\ae2d";
  }
  &wrench:before {
    content: "\ae2e";
  }
  &pendrive:before {
    content: "\ae6a";
  }
  &sim_card:before {
    content: "\ae6b";
  }
  &usb:before {
    content: "\ae6c";
  }
  &usb_cable:before {
    content: "\ae6d";
  }
  &usb_cable_power:before {
    content: "\ae6f";
  }
  &bluetooth:before {
    content: "\ae7a";
  }
  &bluetooth_2:before {
    content: "\ae7b";
  }
  &arcade:before {
    content: "\ae80";
  }
  &joystick:before {
    content: "\ae7f";
  }
  &old_game_console:before {
    content: "\ae7e";
  }
  &packman:before,
  &pacman:before{
    content: "\ae7d";
  }
  &tetris:before {
    content: "\ae7c";
  }
}