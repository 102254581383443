@font-face {
  font-family: 'fb_beach';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_beach.eot?etugip');
  src: url('fonts/fb_beach.eot?etugip#iefix') format('embedded-opentype'),
  url('fonts/fb_beach.woff2?etugip') format('woff2'),
  url('fonts/fb_beach.woff?etugip') format('woff'),
  url('fonts/fb_beach.ttf?etugip') format('truetype'),
  url('fonts/fb_beach.svg?etugip#fb_beach') format('svg');
  font-display: block;
}

[class^="fb_beach_"], [class*=" fb_beach_"] {
  font-family: 'fb_beach' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}