.fb_bathroom_ {
  &bath_tub:before {
    content: "\b900";
  }
  &bathrobe:before {
    content: "\b901";
  }
  &jacuzzi:before {
    content: "\b902";
  }
  &phon:before {
    content: "\b903";
  }
  &shower:before {
    content: "\b904";
  }
  &soap:before {
    content: "\b905";
  }
  &towel:before {
    content: "\b906";
  }
  &bidet:before {
    content: "\b912";
  }
  &toilet:before {
    content: "\b913";
  }
  &toilet_directions:before {
    content: "\b914";
  }
  &toilet_paper:before {
    content: "\b907";
  }
  &towel_rail:before {
    content: "\b908";
  }
  &tub_shower:before {
    content: "\b909";
  }
  &wc:before {
    content: "\b910";
  }
  &wc_bidet:before {
    content: "\b911";
  }
}
