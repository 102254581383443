@font-face {
  font-family: 'fb_sport';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_sport.eot?90aihp');
  src: url('fonts/fb_sport.eot?90aihp#iefix') format('embedded-opentype'),
  url('fonts/fb_sport.woff2?90aihp') format('woff2'),
  url('fonts/fb_sport.woff?90aihp') format('woff'),
  url('fonts/fb_sport.ttf?90aihp') format('truetype'),
  url('fonts/fb_sport.svg?90aihp#fb_sport') format('svg');
  font-display: block;
}

[class^="fb_sport_"], [class*=" fb_sport_"] {
  font-family: 'fb_sport' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}