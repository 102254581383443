.fb_additional_set_1_ {
  &360:before {
    content: "\ab00";
  }
  &accessibility:before {
    content: "\ab01";
  }
  &aerobics:before {
    content: "\ab02";
  }
  &brush:before {
    content: "\ab03";
  }
  &calash:before {
    content: "\ab04";
  }
  &chat:before {
    content: "\ab05";
  }
  &clock:before {
    content: "\ab06";
  }
  &compass:before {
    content: "\ab07";
  }
  &dirham:before {
    content: "\ab08";
  }
  &discount_1:before {
    content: "\ab09";
  }
  &discount_2:before {
    content: "\ab0a";
  }
  &hand_cream:before {
    content: "\ab0b";
  }
  &hourglass:before {
    content: "\ab0c";
  }
  &limousine:before {
    content: "\ab0d";
  }
  &nordic_walking:before {
    content: "\ab0e";
  }
  &paint_brush:before {
    content: "\ab0f";
  }
  &phone:before {
    content: "\ab10";
  }
  &pillow:before {
    content: "\ab11";
  }
  &razor:before {
    content: "\ab12";
  }
  &sakura:before {
    content: "\ab13";
  }
  &shield:before {
    content: "\ab14";
  }
  &shoe_polish:before {
    content: "\ab15";
  }
  &text_content:before {
    content: "\ab16";
  }
  &tooth_brush:before {
    content: "\ab17";
  }
  &tooth_paste:before {
    content: "\ab18";
  }
  &tooth_paste_brush:before {
    content: "\ab19";
  }
  &walking_man:before {
    content: "\ab1a";
  }
  &wind_rose:before {
    content: "\ab1b";
  }
  &window:before {
    content: "\ab1c";
  }
  &360_1:before {
    content: "\ab2c";
  }
  &360_2:before {
    content: "\ab3c";
  }
  &360_3:before {
    content: "\ab4c";
  }
  &ribbon_360:before {
    content: "\ab5c";
  }
  &ribbon_360_image:before {
    content: "\ab6c";
  }
  &ribbon_360_video:before {
    content: "\ab1d";
  }
  &play:before {
    content: "\ab2d";
  }
  &diamond:before {
    content: "\ab1e";
  }
  &dishwasher:before {
    content: "\aba1";
  }
  &file:before {
    content: "\aba2";
  }
  &file_zip:before {
    content: "\aba3";
  }
  &footprint:before {
    content: "\aba4";
  }
  &footprint_2:before {
    content: "\aba5";
  }
  &like:before {
    content: "\aba6";
  }
  &paw:before {
    content: "\aba7";
  }
  &reload:before {
    content: "\aba8";
  }
  &safe_box:before {
    content: "\aba9";
  }
  &suitcase:before {
    content: "\abb1";
  }
  &woman_dress:before {
    content: "\abb2";
  }
  &baby:before {
    content: "\ab21";
  }
  &infant:before {
    content: "\ab22";
  }
  &monster:before {
    content: "\ab26";
  }
  &alien:before {
    content: "\ab23";
  }
  &crown_king:before {
    content: "\ab24";
  }
  &crown_queen:before {
    content: "\ab25";
  }
  &piggy_bank:before {
    content: "\ab28";
  }
  &pets_not_allowed_dog:before {
    content: "\ab27";
  }
  &pets_not_allowed_paw:before {
    content: "\ab29";
  }
  &campfire:before {
    content: "\ab30";
  }
  &closet:before {
    content: "\ab31";
  }
  &info_balloon:before {
    content: "\ab32";
  }
  &question_balloon:before {
    content: "\ab33";
  }
  &table:before {
    content: "\ab34";
  }
}