@font-face {
  font-family: 'fb_additional_set_2';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_additional_set_2.eot?etugip');
  src: url('fonts/fb_additional_set_2.eot?etugip#iefix') format('embedded-opentype'),
  url('fonts/fb_additional_set_2.woff2?etugip') format('woff2'),
  url('fonts/fb_additional_set_2.woff?etugip') format('woff'),
  url('fonts/fb_additional_set_2.ttf?etugip') format('truetype'),
  url('fonts/fb_additional_set_2.svg?etugip#fb_additional_set_2') format('svg');
  font-display: block;
}

[class^="fb_additional_set_2_"], [class*=" fb_additional_set_2_"] {
  font-family: 'fb_additional_set_2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}