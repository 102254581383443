.fb_restaurant_ {
  &coffee:before {
    content: "\ec00";
  }
  &coffee_beans:before {
    content: "\ec01";
  }
  &ice_cream:before {
    content: "\ec02";
  }
  &menu:before {
    content: "\ec03";
  }
  &milk:before {
    content: "\ec04";
  }
  &tea:before {
    content: "\ec05";
  }
  &teapot:before {
    content: "\ec06";
  }
  &yoghurt:before {
    content: "\ec07";
  }
  &yoghurt_2:before {
    content: "\ec08";
  }
  &bar:before {
    content: "\ec09";
  }
  &beer:before {
    content: "\ec0a";
  }
  &bio:before {
    content: "\ec0b";
  }
  &bread:before {
    content: "\ec0c";
  }
  &breakfast:before {
    content: "\ec0d";
  }
  &catering:before {
    content: "\ec0e";
  }
  &cheese:before {
    content: "\ec0f";
  }
  &chef_toque:before {
    content: "\ec10";
  }
  &chicken:before {
    content: "\ec11";
  }
  &cocktail:before {
    content: "\ec12";
  }
  &cutlery:before {
    content: "\ec13";
  }
  &dessert:before {
    content: "\ec14";
  }
  &fast_food:before {
    content: "\ec15";
  }
  &fish:before {
    content: "\ec16";
  }
  &fruits:before {
    content: "\ec17";
  }
  &game:before {
    content: "\ec18";
  }
  &gluten_free:before {
    content: "\ec19";
  }
  &local_typical_products:before {
    content: "\ec1a";
  }
  &lounge_bar:before {
    content: "\ec1b";
  }
  &meal:before {
    content: "\ec1c";
  }
  &meat:before {
    content: "\ec1d";
  }
  &pasta:before {
    content: "\ec1e";
  }
  &piano_bar:before {
    content: "\ec1f";
  }
  &pizzeria:before {
    content: "\ec20";
  }
  &restaurant:before {
    content: "\ec21";
  }
  &shellfish:before {
    content: "\ec22";
  }
  &shop:before {
    content: "\ec23";
  }
  &skewers:before {
    content: "\ec24";
  }
  &spicy:before {
    content: "\ec25";
  }
  &sushi:before {
    content: "\ec26";
  }
  &vegan:before {
    content: "\ec27";
  }
  &vegetables:before {
    content: "\ec28";
  }
  &vegetables_2:before {
    content: "\ec29";
  }
  &vegetarian:before {
    content: "\ec2a";
  }
  &wine:before {
    content: "\ec2b";
  }
  &wine_store:before {
    content: "\ec2c";
  }
  &cake:before {
    content: "\e990";
  }
}