.fb_allergens_ {
  &acarus_free:before {
    content: "\ad00";
  }
  &artificial_colors_free:before {
    content: "\ad01";
  }
  &artificial_sweeteners_free:before {
    content: "\ad02";
  }
  &caffeine_free:before {
    content: "\ad03";
  }
  &celery_free:before {
    content: "\ad04";
  }
  &crustaceans_free:before {
    content: "\ad05";
  }
  &dust_free:before {
    content: "\ad06";
  }
  &eggs_free:before {
    content: "\ad07";
  }
  &eggs_fried_free:before {
    content: "\ad08";
  }
  &fish_free:before {
    content: "\ad09";
  }
  &gluten_free:before {
    content: "\ad0a";
  }
  &gmo_free:before {
    content: "\ad0b";
  }
  &insect_free:before {
    content: "\ad0c";
  }
  &lactose_free:before {
    content: "\ad0d";
  }
  &lupins_free:before {
    content: "\ad0e";
  }
  &bacteria_free:before {
    content: "\ad0f";
  }
  &mold_free:before {
    content: "\ad10";
  }
  &mustard_free:before {
    content: "\ad11";
  }
  &nuts_free:before {
    content: "\ad12";
  }
  &peanuts_butter_free:before {
    content: "\ad13";
  }
  &peanuts_free:before {
    content: "\ad14";
  }
  &pollen_free:before {
    content: "\ad15";
  }
  &salt_free:before {
    content: "\ad16";
  }
  &seasame_free:before {
    content: "\ad17";
  }
  &seashell_free:before {
    content: "\ad18";
  }
  &soy_free:before {
    content: "\ad19";
  }
  &soy_sauce_free:before {
    content: "\ad1a";
  }
  &strawberries_free:before {
    content: "\ad1b";
  }
  &sugar_free:before {
    content: "\ad1c";
  }
  &sulphite_free:before {
    content: "\ad1d";
  }
  &mosquito_free:before {
    content: "\ad1e";
  }
}