.fb_room_ {
  &alarm:before {
    content: "\c900";
  }
  &bed:before {
    content: "\c901";
  }
  &butler:before {
    content: "\c902";
  }
  &courtesy_set:before {
    content: "\c903";
  }
  &flip_flops:before {
    content: "\c904";
  }
  &hanger:before {
    content: "\c905";
  }
  &in_room_service:before {
    content: "\c906";
  }
  &ironing_service:before {
    content: "\c907";
  }
  &kettle:before {
    content: "\c908";
  }
  &living:before {
    content: "\c909";
  }
  &minibar:before {
    content: "\c90a";
  }
  &radio:before {
    content: "\c90b";
  }
  &room:before {
    content: "\c90c";
  }
  &room_key:before {
    content: "\c90d";
  }
  &safe:before {
    content: "\c90e";
  }
  &satellite_tv:before {
    content: "\c90f";
  }
  &sky_pay_tv:before {
    content: "\c910";
  }
  &small_kitchen:before {
    content: "\c911";
  }
  &tv_led:before {
    content: "\c912";
  }
  &tv_old:before {
    content: "\c913";
  }
  &wardrobe:before {
    content: "\c914";
  }
  &room_night:before {
    content: "\c915";
  }
}
