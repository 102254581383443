.fb_meeting_2_{
  &armchair:before {
    content: "\bb04";
  }
  &ballet:before {
    content: "\bb05";
  }
  &banquet:before {
    content: "\bb06";
  }
  &bar_chart_down:before {
    content: "\bb07";
  }
  &bar_chart_up:before {
    content: "\bb08";
  }
  &blackboard:before {
    content: "\bb09";
  }
  &boardroom:before {
    content: "\bb0a";
  }
  &burlesque:before {
    content: "\bb0b";
  }
  &cabaret:before {
    content: "\bb0c";
  }
  &chair:before {
    content: "\bb0d";
  }
  &chandelier:before {
    content: "\bb0e";
  }
  &chandelier_2:before {
    content: "\bb0f";
  }
  &cinema:before {
    content: "\bb10";
  }
  &classroom:before {
    content: "\bb11";
  }
  &cocktail:before {
    content: "\bb12";
  }
  &dance:before {
    content: "\bb13";
  }
  &dimensions:before {
    content: "\bb14";
  }
  &director_chair:before {
    content: "\bb15";
  }
  &electric_plug:before {
    content: "\bb16";
  }
  &electric_socket:before {
    content: "\bb17";
  }
  &empty_room:before {
    content: "\bb18";
  }
  &floor:before {
    content: "\bb19";
  }
  &flowchart:before {
    content: "\bb1a";
  }
  &gala_dinner:before {
    content: "\bb1b";
  }
  &gala_dinner_table:before {
    content: "\bb1c";
  }
  &h_shape:before {
    content: "\bb1d";
  }
  &handshake:before {
    content: "\bb1e";
  }
  &hollowsquare:before {
    content: "\bb1f";
  }
  &i_shape:before {
    content: "\bb20";
  }
  &interactive_board:before {
    content: "\bb21";
  }
  &lamp:before {
    content: "\bb22";
  }
  &laser_pointer:before {
    content: "\bb23";
  }
  &layers:before {
    content: "\bb24";
  }
  &lectern:before {
    content: "\bb25";
  }
  &lectern_2:before {
    content: "\bb26";
  }
  &meeting:before {
    content: "\bb27";
  }
  &meeting_2:before {
    content: "\bb28";
  }
  &mobile_walls:before {
    content: "\bb29";
  }
  &model_3d:before {
    content: "\bb2a";
  }
  &modem:before {
    content: "\bb2b";
  }
  &multiple_units:before {
    content: "\bb2c";
  }
  &o_shape:before {
    content: "\bb2d";
  }
  &office_chair:before {
    content: "\bb2e";
  }
  &organogram:before {
    content: "\bb2f";
  }
  &overhead_projector:before {
    content: "\bb30";
  }
  &pass:before {
    content: "\bb31";
  }
  &pie_chart:before {
    content: "\bb32";
  }
  &projector:before {
    content: "\bb33";
  }
  &projector_2:before {
    content: "\bb34";
  }
  &red_carpet:before {
    content: "\bb35";
  }
  &remote_control:before {
    content: "\bb36";
  }
  &retractable_screen:before {
    content: "\bb37";
  }
  &rocking_chair:before {
    content: "\bb38";
  }
  &room_height:before {
    content: "\bb39";
  }
  &runway:before {
    content: "\bb3a";
  }
  &stool:before {
    content: "\bb3b";
  }
  &surface:before {
    content: "\bb3c";
  }
  &t_shape:before {
    content: "\bb3d";
  }
  &theater:before {
    content: "\bb3e";
  }
  &theatre_curtains:before {
    content: "\bb3f";
  }
  &u_shape:before {
    content: "\bb40";
  }
  &video_conference:before {
    content: "\bb41";
  }
  &vintage_chair:before {
    content: "\bb42";
  }
  &vip_pass:before {
    content: "\bb43";
  }
  &white_board:before {
    content: "\bb44";
  }
  &arrow_width:before {
    content: "\a38c";
  }
  &arrow_height:before {
    content: "\a38d";
  }
  &arrow_length:before {
    content: "\a38e";
  }
  &chairs_circle:before {
    content: "\bb54";
  }
  &corridor:before {
    content: "\bb55";
  }
  &auditorium:before {
    content: "\bb56";
  }
}