@font-face {
  font-family: 'fb_media_player';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_media_player.eot?odmroz');
  src: url('fonts/fb_media_player.eot?odmroz#iefix') format('embedded-opentype'),
  url('fonts/fb_media_player.woff2?odmroz') format('woff2'),
  url('fonts/fb_media_player.woff?odmroz') format('woff'),
  url('fonts/fb_media_player.ttf?odmroz') format('truetype'),
  url('fonts/fb_media_player.svg?odmroz#fb_media_player') format('svg');
  font-display: block;
}

[class^="fb_media_player_"], [class*=" fb_media_player_"] {
  font-family: 'fb_media_player' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
