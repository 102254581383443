.fb_service_ {
  &air_conditioning:before {
    content: "\a900";
  }
  &atm:before {
    content: "\a901";
  }
  &atm_2:before {
    content: "\a902";
  }
  &babysitting:before {
    content: "\a903";
  }
  &baby_changing_table:before {
    content: "\a904";
  }
  &bar:before {
    content: "\a905";
  }
  &bazar:before {
    content: "\a906";
  }
  &beach:before {
    content: "\a907";
  }
  &books:before {
    content: "\a908";
  }
  &breakfast:before {
    content: "\a909";
  }
  &catering:before {
    content: "\a90a";
  }
  &couple:before {
    content: "\a90b";
  }
  &credit_card:before {
    content: "\a90c";
  }
  &disabled_guests:before {
    content: "\a90d";
  }
  &elevator:before {
    content: "\a90e";
  }
  &escalator:before {
    content: "\a90f";
  }
  &escalator_down:before {
    content: "\a910";
  }
  &escalator_up:before {
    content: "\a911";
  }
  &extinguisher:before {
    content: "\a912";
  }
  &family:before {
    content: "\a913";
  }
  &fast_food:before {
    content: "\a914";
  }
  &first_aid:before {
    content: "\a915";
  }
  &gay_friendly_female:before {
    content: "\a916";
  }
  &gay_friendly_male:before {
    content: "\a917";
  }
  &gym_fitness:before {
    content: "\a918";
  }
  &hairdresser:before {
    content: "\a919";
  }
  &heating:before {
    content: "\a91a";
  }
  &internet:before {
    content: "\a91b";
  }
  &internet_2:before {
    content: "\a91c";
  }
  &junior_club_miniclub:before {
    content: "\a91d";
  }
  &laundry:before {
    content: "\a91e";
  }
  &library:before {
    content: "\a91f";
  }
  &local_typical_products:before {
    content: "\a920";
  }
  &lounge_bar:before {
    content: "\a921";
  }
  &luggage:before {
    content: "\a922";
  }
  &luggage_drop:before {
    content: "\a923";
  }
  &luggage_transport:before {
    content: "\a924";
  }
  &meal:before {
    content: "\a925";
  }
  &meeting_b2b_congress:before {
    content: "\a026";
  }
  &newspaper:before {
    content: "\a027";
  }
  &newsstand:before {
    content: "\a028";
  }
  &no_smoking:before {
    content: "\a029";
  }
  &nursery:before {
    content: "\a02a";
  }
  &pet_friendly:before {
    content: "\a02b";
  }
  &phone:before {
    content: "\a02c";
  }
  &photocopy_service:before {
    content: "\a021";
  }
  &pianobar:before {
    content: "\a92e";
  }
  &picnic:before {
    content: "\a92f";
  }
  &pizzeria:before {
    content: "\a930";
  }
  &postal_service:before {
    content: "\a931";
  }
  &radiator:before {
    content: "\a932";
  }
  &reception:before {
    content: "\a933";
  }
  &recycle:before {
    content: "\a934";
  }
  &refrigerator:before {
    content: "\a935";
  }
  &restaurant:before {
    content: "\a936";
  }
  &seaside:before {
    content: "\a937";
  }
  &shop:before {
    content: "\a938";
  }
  &smartphone:before {
    content: "\a939";
  }
  &smoking:before {
    content: "\a93a";
  }
  &stairs:before {
    content: "\a93b";
  }
  &stairs_down:before {
    content: "\a93c";
  }
  &stairs_up:before {
    content: "\a93d";
  }
  &stool:before {
    content: "\a93e";
  }
  &tailoring:before {
    content: "\a93f";
  }
  &terrace:before {
    content: "\a940";
  }
  &tickets_reservation:before {
    content: "\a941";
  }
  &translation_services:before {
    content: "\a942";
  }
  &user:before {
    content: "\a943";
  }
  &user_circle:before {
    content: "\a944";
  }
  &vacuum_cleaner:before {
    content: "\a945";
  }
  &videogame:before {
    content: "\a946";
  }
  &webcam:before {
    content: "\a047";
  }
  &wedding_ceremony:before {
    content: "\a048";
  }
  &wifi:before {
    content: "\a049";
  }
  &wii:before {
    content: "\a04a";
  }
  &wine:before {
    content: "\a04b";
  }
  &wine_store:before {
    content: "\a04c";
  }
  &suit:before {
    content: "\a050";
  }
  &bow_tie:before {
    content: "\a051";
  }
  &business_man:before {
    content: "\a052";
  }
  &business_woman:before {
    content: "\a053";
  }
  &envelope:before {
    content: "\a054";
  }
  &handbag:before {
    content: "\a055";
  }
  &man:before {
    content: "\a056";
  }
  &man_2:before {
    content: "\a057";
  }
  &team:before {
    content: "\a058";
  }
  &tie:before {
    content: "\a059";
  }
  &woman:before {
    content: "\a060";
  }
  &woman_2:before {
    content: "\a061";
  }
}