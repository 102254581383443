.fb_room_2_ {
  &adjustable_tv:before {
    content: "\a200";
  }
  &alarm_clock:before {
    content: "\a201";
  }
  &alarm_clock_2:before {
    content: "\a202";
  }
  &amazon_alexa:before {
    content: "\a203";
  }
  &amazon_alexa_solid:before {
    content: "\a204";
  }
  &american_coffee_maker:before {
    content: "\a205";
  }
  &baby_crib:before {
    content: "\a206";
  }
  &bunk_bed:before {
    content: "\a207";
  }
  &coffee_maker:before {
    content: "\a208";
  }
  &desk:before {
    content: "\a209";
  }
  &digital_concierge:before {
    content: "\a20a";
  }
  &docking_station:before {
    content: "\a20b";
  }
  &docking_station_2:before {
    content: "\a20c";
  }
  &double_tv:before {
    content: "\a20d";
  }
  &fireplace:before {
    content: "\a20e";
  }
  &grandfather_clock:before {
    content: "\a20f";
  }
  &ironing_board:before {
    content: "\a210";
  }
  &king_size_bed:before {
    content: "\a211";
  }
  &landline_phone:before {
    content: "\a212";
  }
  &landline_phone_2:before {
    content: "\a213";
  }
  &landline_phone_old:before {
    content: "\a214";
  }
  &mirror_big:before {
    content: "\a215";
  }
  &mirror_little:before {
    content: "\a216";
  }
  &mirroring_screen:before {
    content: "\a217";
  }
  &moka:before {
    content: "\a218";
  }
  &queen_size_bed:before {
    content: "\a219";
  }
  &room_card:before {
    content: "\a21a";
  }
  &room_card_2:before {
    content: "\a21b";
  }
  &round_bed:before {
    content: "\a21c";
  }
  &single_bed:before {
    content: "\a21d";
  }
  &smart_speaker:before {
    content: "\a21e";
  }
  &sofa:before {
    content: "\a21f";
  }
  &sofa_bed:before {
    content: "\a220";
  }
  &sound:before {
    content: "\a221";
  }
  &soundproof_room_walls:before {
    content: "\a222";
  }
  &stroller:before {
    content: "\a223";
  }
  &double_bed_or_sofa:before {
    content: "\a224";
  }
  &double_bed_plus_sofa:before {
    content: "\a225";
  }
  &double_or_single_bed:before {
    content: "\a226";
  }
  &double_plus_single_bed:before {
    content: "\a227";
  }
  &king_or_queen_bed:before {
    content: "\a228";
  }
  &king_plus_queen_bed:before {
    content: "\a229";
  }
  &single_bed_or_sofa:before {
    content: "\a22a";
  }
  &single_bed_plus_sofa:before {
    content: "\a22b";
  }
  &single_or_french_bed:before {
    content: "\a22c";
  }
  &single_plus_single_bed:before {
    content: "\a22d";
  }
  &keypad_door:before {
    content: "\a22e";
  }
  &adults_only:before {
    content: "\a22f";
  }
  &pay_tv:before {
    content: "\a300";
  }
}