.fb_icon_ {
  &address_book:before {
    content: "\fb50";
  }
  &arrow_down:before {
    content: "\fb51";
  }
  &arrow_down_left:before {
    content: "\fb52";
  }
  &arrow_down_right:before {
    content: "\fb53";
  }
  &arrow_left:before {
    content: "\fb54";
  }
  &arrow_right:before {
    content: "\fb55";
  }
  &arrow_up:before {
    content: "\fb56";
  }
  &arrow_up_left:before {
    content: "\fb57";
  }
  &arrow_up_right:before {
    content: "\fb58";
  }
  &attachment:before {
    content: "\fb59";
  }
  &award:before {
    content: "\fb5a";
  }
  &ban:before {
    content: "\fb5b";
  }
  &barcode:before {
    content: "\fb5c";
  }
  &bookmark:before {
    content: "\fb5d";
  }
  &calendar:before {
    content: "\fb5e";
  }
  &camera_photo:before {
    content: "\fb5f";
  }
  &cart:before {
    content: "\fb60";
  }
  &checkmark:before {
    content: "\fb61";
  }
  &child:before {
    content: "\fb62";
  }
  &chrome:before {
    content: "\fb63";
  }
  &close:before {
    content: "\fb64";
  }
  &cogs:before {
    content: "\fb65";
  }
  &desktop:before {
    content: "\fb66";
  }
  &download:before {
    content: "\fb67";
  }
  &droplet:before {
    content: "\fb68";
  }
  &eye:before {
    content: "\fb69";
  }
  &eye_blocked:before {
    content: "\fb6a";
  }
  &female:before {
    content: "\fb6b";
  }
  &file_excel:before {
    content: "\fb6c";
  }
  &file_pdf:before {
    content: "\fb6d";
  }
  &file_picture:before {
    content: "\fb6e";
  }
  &file_word:before {
    content: "\fb6f";
  }
  &film:before {
    content: "\fb70";
  }
  &film_ciak:before {
    content: "\fb71";
  }
  &fire:before {
    content: "\fb72";
  }
  &firefox:before {
    content: "\fb73";
  }
  &gender_equality:before {
    content: "\fb74";
  }
  &gift:before {
    content: "\fb75";
  }
  &hammer:before {
    content: "\fb76";
  }
  &heart:before {
    content: "\fb77";
  }
  &image:before {
    content: "\fb78";
  }
  &images:before {
    content: "\fb79";
  }
  &internet_explorer:before {
    content: "\fb7a";
  }
  &lab:before {
    content: "\fb7b";
  }
  &laptop:before {
    content: "\fb7c";
  }
  &male:before {
    content: "\fb7d";
  }
  &microphone:before {
    content: "\fb7e";
  }
  &microphone_old:before {
    content: "\fb7f";
  }
  &mobile:before {
    content: "\fb80";
  }
  &move:before {
    content: "\fb81";
  }
  &newborn:before {
    content: "\fb82";
  }
  &opera:before {
    content: "\fb83";
  }
  &pencil:before {
    content: "\fb84";
  }
  &power_cord:before {
    content: "\fb85";
  }
  &print_fax:before {
    content: "\fb86";
  }
  &qrcode:before {
    content: "\fb87";
  }
  &road:before {
    content: "\fb88";
  }
  &safari:before {
    content: "\fb89";
  }
  &scissors:before {
    content: "\fb8a";
  }
  &search:before {
    content: "\fb8b";
  }
  &smile:before {
    content: "\fb8c";
  }
  &star:before {
    content: "\fb8d";
  }
  &tablet:before {
    content: "\fb8e";
  }
  &tag:before {
    content: "\fb8f";
  }
  &trash:before {
    content: "\fb90";
  }
  &trophy:before {
    content: "\fb91";
  }
  &upload:before {
    content: "\fb92";
  }
  &wrench:before {
    content: "\fb93";
  }
  &printer:before{
    content: "\fb94";
  }
  &devices:before{
    content: "\fb95";
  }
}
