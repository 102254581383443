.fb_architecture_{

  &column:before {
    content: "\af00";
  }
  &african_mask:before {
    content: "\af01";
  }
  &african_shield:before {
    content: "\af02";
  }
  &alexander_nevsky_cathedral:before {
    content: "\af03";
  }
  &alhambra:before {
    content: "\af04";
  }
  &angkor_wat:before {
    content: "\af05";
  }
  &arabic_gate:before {
    content: "\af06";
  }
  &arabic_window:before {
    content: "\af07";
  }
  &astronomical_clock:before {
    content: "\af08";
  }
  &atomium:before {
    content: "\af09";
  }
  &aztec_mask:before {
    content: "\af0a";
  }
  &azuleios:before {
    content: "\af0b";
  }
  &balcony:before {
    content: "\af0c";
  }
  &bunker:before {
    content: "\af0d";
  }
  &barn:before {
    content: "\af0e";
  }
  &belem_tower:before {
    content: "\af0f";
  }
  &belvedere_vienna:before {
    content: "\af10";
  }
  &big_ben:before {
    content: "\af11";
  }
  &bow_window:before {
    content: "\af12";
  }
  &bran_castle:before {
    content: "\af13";
  }
  &brandenburg_gate:before {
    content: "\af14";
  }
  &bridge:before {
    content: "\af15";
  }
  &bridge_2:before {
    content: "\af16";
  }
  &bridge_3:before {
    content: "\af17";
  }
  &bridge_4:before {
    content: "\af18";
  }
  &bridge_5:before {
    content: "\af19";
  }
  &buddha:before {
    content: "\af1a";
  }
  &building:before {
    content: "\af1b";
  }
  &burj_al_arab:before {
    content: "\af1c";
  }
  &cabin:before {
    content: "\af1d";
  }
  &cable_railway:before {
    content: "\af1e";
  }
  &camper:before {
    content: "\af1f";
  }
  &caravan:before {
    content: "\af20";
  }
  &castle:before {
    content: "\af21";
  }
  &castle_2:before {
    content: "\af22";
  }
  &castle_drawbridge:before {
    content: "\af23";
  }
  &cathedral:before {
    content: "\af24";
  }
  &cave:before {
    content: "\af25";
  }
  &cctv_headquarters:before {
    content: "\af26";
  }
  &charles_bridge:before {
    content: "\af27";
  }
  &chinese_temple:before {
    content: "\af28";
  }
  &christ_the_redeemer:before {
    content: "\af29";
  }
  &church:before {
    content: "\af2a";
  }
  &church_our_lady_before_tyn:before {
    content: "\af2b";
  }
  &clock_tower:before {
    content: "\af2c";
  }
  &colosseum:before {
    content: "\af2d";
  }
  &cottage:before {
    content: "\af2e";
  }
  &crane:before {
    content: "\af2f";
  }
  &dam:before {
    content: "\af30";
  }
  &dam_2:before {
    content: "\af31";
  }
  &djinguereber_mosque:before {
    content: "\af32";
  }
  &door_handle:before {
    content: "\af33";
  }
  &drawbridge:before {
    content: "\af34";
  }
  &egeskov_castle:before {
    content: "\af35";
  }
  &eiffel_tower:before {
    content: "\af36";
  }
  &el_capitolio:before {
    content: "\af37";
  }
  &emerald_temple:before {
    content: "\af38";
  }
  &empire_state_building:before {
    content: "\af39";
  }
  &equestrian_statue:before {
    content: "\af3a";
  }
  &farmhouse:before {
    content: "\af3b";
  }
  &fence:before {
    content: "\af3c";
  }
  &fernsehturm:before {
    content: "\af3d";
  }
  &florence_cathedral:before {
    content: "\af3e";
  }
  &forbidden_city:before {
    content: "\af3f";
  }
  &fort:before {
    content: "\af40";
  }
  &fountain:before {
    content: "\af41";
  }
  &gargoyle:before {
    content: "\af42";
  }
  &gate:before {
    content: "\af43";
  }
  &gateway_arch:before {
    content: "\af44";
  }
  &gazebo:before {
    content: "\af45";
  }
  &golden_gate_bridge:before {
    content: "\af46";
  }
  &gothic_window:before {
    content: "\af47";
  }
  &hagia_sophia:before {
    content: "\af48";
  }
  &hanok:before {
    content: "\af49";
  }
  &home_door:before {
    content: "\af4a";
  }
  &horse_carousel:before,
  &hourse_carousel:before {
    content: "\af4b";
  }
  &house:before {
    content: "\af4c";
  }
  &house_boat:before {
    content: "\af4d";
  }
  &hungarian_parliament:before {
    content: "\af4e";
  }
  &hut:before {
    content: "\af4f";
  }
  &igloo:before {
    content: "\af50";
  }
  &italian_garden:before {
    content: "\af51";
  }
  &kaaba:before {
    content: "\af52";
  }
  &kairouan_mosque:before {
    content: "\af53";
  }
  &lempuyang_temple:before {
    content: "\af54";
  }
  &loggia:before {
    content: "\af55";
  }
  &london_eye:before {
    content: "\af56";
  }
  &lotus_pond_taiwan:before {
    content: "\af57";
  }
  &louvre:before {
    content: "\af58";
  }
  &manor:before {
    content: "\af59";
  }
  &maya_pyramid:before {
    content: "\af5a";
  }
  &mecca:before {
    content: "\af5b";
  }
  &merlion:before {
    content: "\af5c";
  }
  &milan_cathedral:before {
    content: "\af5d";
  }
  &mill:before {
    content: "\af5e";
  }
  &moai_statue:before {
    content: "\af5f";
  }
  &modern_house:before {
    content: "\af60";
  }
  &modern_sculpture:before {
    content: "\af61";
  }
  &mole_antonelliana:before {
    content: "\af62";
  }
  &mombasa_tusks:before {
    content: "\af63";
  }
  &mosque:before {
    content: "\af64";
  }
  &nagoya_castle:before {
    content: "\af65";
  }
  &notre_dame:before {
    content: "\af66";
  }
  &obelisk:before {
    content: "\af67";
  }
  &pagoda:before {
    content: "\af68";
  }
  &palace_culture_science_warsav:before {
    content: "\af69";
  }
  &petronas_towers:before {
    content: "\af6a";
  }
  &pisa_tower:before {
    content: "\af6b";
  }
  &portico:before {
    content: "\af6c";
  }
  &portico_1:before {
    content: "\af6d";
  }
  &porto_cathedral:before {
    content: "\af6e";
  }
  &pottery:before {
    content: "\af6f";
  }
  &pyramid:before {
    content: "\af70";
  }
  &rack_railway:before {
    content: "\af71";
  }
  &rialto_bridge:before {
    content: "\af72";
  }
  &roman_aqueduct:before {
    content: "\af73";
  }
  &rose_window:before {
    content: "\af74";
  }
  &ruins:before {
    content: "\af75";
  }
  &sagrada_familia:before {
    content: "\af76";
  }
  &saint_basil_cathedral:before {
    content: "\af77";
  }
  &samarra_mosque:before {
    content: "\af78";
  }
  &san_francesco_cathedral:before {
    content: "\af79";
  }
  &santorini:before {
    content: "\af7a";
  }
  &sky_tower:before {
    content: "\af7b";
  }
  &sphinx:before {
    content: "\af7c";
  }
  &spiral_staircase:before {
    content: "\af7d";
  }
  &st_james_cathedral:before {
    content: "\af7e";
  }
  &st_mary_axe:before {
    content: "\af7f";
  }
  &st_volodymyr_cathedral:before {
    content: "\af80";
  }
  &stairs:before {
    content: "\af81";
  }
  &stairs_2:before {
    content: "\af82";
  }
  &statue_man:before {
    content: "\af83";
  }
  &statue_of_liberty:before {
    content: "\af84";
  }
  &statue_woman:before {
    content: "\af85";
  }
  &stupa:before {
    content: "\af86";
  }
  &sydney_harbour_bridge:before {
    content: "\af87";
  }
  &sydney_opera_house:before {
    content: "\af88";
  }
  &synagogue:before {
    content: "\af89";
  }
  &taj_mahal:before {
    content: "\af8a";
  }
  &teepee:before {
    content: "\af8b";
  }
  &temple:before {
    content: "\af8c";
  }
  &tent:before {
    content: "\af8d";
  }
  &the_capitol:before {
    content: "\af8e";
  }
  &tiki_mask:before {
    content: "\af8f";
  }
  &torii:before {
    content: "\af90";
  }
  &totem:before {
    content: "\af91";
  }
  &tower:before {
    content: "\af92";
  }
  &tower_bridge:before {
    content: "\af93";
  }
  &townhouse:before {
    content: "\af94";
  }
  &townhouse_2:before {
    content: "\af95";
  }
  &treehouse:before {
    content: "\af96";
  }
  &triomph_arc:before {
    content: "\af97";
  }
  &trullo:before {
    content: "\af98";
  }
  &vatican_city:before {
    content: "\af99";
  }
  &venetian_gothic_window:before {
    content: "\af9a";
  }
  &veranda:before {
    content: "\af9b";
  }
  &vienna_secession:before {
    content: "\af9c";
  }
  &wall:before {
    content: "\af9d";
  }
  &water_well:before {
    content: "\af9e";
  }
  &whale_sculpture_oeiras:before {
    content: "\af9f";
  }
  &window:before {
    content: "\afa0";
  }
  &window_2:before {
    content: "\afa1";
  }
  &yurt:before {
    content: "\afa2";
  }
  &wall_fountain:before {
    content: "\af800";
  }
  &countryside:before {
    content: "\afa3";
  }
  &desert:before {
    content: "\afa4";
  }
  &desert_2:before {
    content: "\afa5";
  }
  &forest:before {
    content: "\afa6";
  }
  &graveyard:before {
    content: "\afa7";
  }
  &landscape:before {
    content: "\afa8";
  }
  &mountain:before {
    content: "\afa9";
  }
  &pond:before {
    content: "\afb0";
  }
  &snowy_mountain:before {
    content: "\afb1";
  }
  &volcanic_eruption:before {
    content: "\afb2";
  }
  &volcano:before {
    content: "\afb3";
  }
  &waterfall:before {
    content: "\afb4";
  }
  &woods:before {
    content: "\afb5";
  }
  &cobblestone_floor:before {
    content: "\afb6";
  }
  &floor_tiles:before {
    content: "\afb7";
  }
  &floor_tiles_2:before {
    content: "\afb8";
  }
  &glass:before {
    content: "\afb9";
  }
  &majolica:before {
    content: "\afaa";
  }
  &marble:before {
    content: "\afab";
  }
  &parquet:before {
    content: "\afac";
  }
  &stone_floor:before {
    content: "\afad";
  }
  &terracotta_floor:before {
    content: "\afae";
  }
  &wall_bricks:before {
    content: "\afaf";
  }
  &wood_panel:before {
    content: "\afba";
  }
  &wood_floor:before {
    content: "\afbb";
  }
  &castle_3:before {
    content: "\afbc";
  }
  &cave_paintings:before {
    content: "\afbd";
  }
  &covered_bridge:before {
    content: "\afbe";
  }
  &dolmen:before {
    content: "\afbf";
  }
  &glacier:before {
    content: "\afca";
  }
  &iceberg:before {
    content: "\afce";
  }
  &lighthouse:before {
    content: "\afcb";
  }
  &northern_lights:before {
    content: "\afcc";
  }
  &suspended_bridge:before {
    content: "\afcd";
  }
  &buddha_head:before {
    content: "\afcf";
  }
  &canyon:before {
    content: "\afda";
  }
  &cliff:before {
    content: "\afdb";
  }
  &coastal_city:before {
    content: "\afdc";
  }
  &geyser:before {
    content: "\afdd";
  }
  &hamlet:before {
    content: "\afde";
  }
  &maze:before {
    content: "\afdf";
  }
  &nazca:before {
    content: "\afea";
  }
  &ravine:before {
    content: "\afeb";
  }
  &river:before {
    content: "\afec";
  }
  &submerged_ruins:before {
    content: "\afed";
  }
  &waterfall_2:before {
    content: "\afee";
  }
  &doge_palace:before {
    content: "\afc0";
  }
  &fleur_de_lys:before {
    content: "\afc1";
  }
  &saint_mark_lion:before {
    content: "\afc2";
  }
  &saint_mark_lion_book:before {
    content: "\afc3";
  }
  &akasaka_hikawa_shrine:before {
    content: "\afc4";
  }
  &cathedral_2:before {
    content: "\afc5";
  }
  &japanese_building:before {
    content: "\afc6";
  }
  &kabukiza_theatre:before {
    content: "\afc7";
  }
  &national_art_center_tokyo:before {
    content: "\afc8";
  }
  &roppongi_hills:before {
    content: "\afc9";
  }
  &sea_stack:before {
    content: "\afd0";
  }
  &tokyo_station:before {
    content: "\afd1";
  }
  &toranomon_hills:before {
    content: "\afd2";
  }
}