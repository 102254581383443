.fb_food_{

  &alkekengi:before {
    content: "\ba00";
  }
  &ananas:before {
    content: "\ba01";
  }
  &apple:before {
    content: "\ba02";
  }
  &artichoke:before {
    content: "\ba03";
  }
  &arugula:before {
    content: "\ba04";
  }
  &asparagus:before {
    content: "\ba05";
  }
  &avocado:before {
    content: "\ba06";
  }
  &bacon:before {
    content: "\ba07";
  }
  &bakery:before {
    content: "\ba08";
  }
  &banana:before {
    content: "\ba09";
  }
  &barbeque:before {
    content: "\ba0a";
  }
  &beans:before {
    content: "\ba0b";
  }
  &beer:before {
    content: "\ba0c";
  }
  &beetroot:before {
    content: "\ba0d";
  }
  &berries:before {
    content: "\ba0e";
  }
  &broccoli:before {
    content: "\ba0f";
  }
  &burger:before {
    content: "\ba10";
  }
  &butcher_knife:before {
    content: "\ba11";
  }
  &can:before {
    content: "\ba12";
  }
  &can_2:before {
    content: "\ba13";
  }
  &carrot:before {
    content: "\ba14";
  }
  &celery:before {
    content: "\ba15";
  }
  &cheese:before {
    content: "\ba16";
  }
  &cherry:before {
    content: "\ba17";
  }
  &cherry_tomato:before {
    content: "\ba18";
  }
  &chestnut:before {
    content: "\ba19";
  }
  &chicken_thigh:before {
    content: "\ba1a";
  }
  &clam:before {
    content: "\ba1b";
  }
  &cocktail:before {
    content: "\ba1c";
  }
  &cocoa:before {
    content: "\ba1d";
  }
  &coconut:before {
    content: "\ba1e";
  }
  &cookie:before {
    content: "\ba1f";
  }
  &cooking:before {
    content: "\ba20";
  }
  &corn:before {
    content: "\ba21";
  }
  &crab:before {
    content: "\ba22";
  }
  &cucumber:before {
    content: "\ba23";
  }
  &cupcake:before {
    content: "\ba24";
  }
  &custard_apple:before {
    content: "\ba25";
  }
  &daikon:before {
    content: "\ba26";
  }
  &date:before {
    content: "\ba27";
  }
  &donuts:before {
    content: "\ba28";
  }
  &dragon_fruit:before {
    content: "\ba29";
  }
  &drink:before {
    content: "\ba2a";
  }
  &durian:before {
    content: "\ba2b";
  }
  &eel:before {
    content: "\ba2c";
  }
  &eggplant:before {
    content: "\ba2d";
  }
  &eggs:before {
    content: "\ba2e";
  }
  &escargot:before {
    content: "\ba2f";
  }
  &fennel:before {
    content: "\ba30";
  }
  &fig:before {
    content: "\ba31";
  }
  &fish_slice:before {
    content: "\ba32";
  }
  &fondue:before {
    content: "\ba33";
  }
  &food_delivery:before {
    content: "\ba34";
  }
  &food_delivery_2:before {
    content: "\ba35";
  }
  &fork_spatula:before {
    content: "\ba36";
  }
  &fortune_cookies:before {
    content: "\ba37";
  }
  &french_fries:before {
    content: "\ba38";
  }
  &fried_eggs:before {
    content: "\ba39";
  }
  &garlic:before {
    content: "\ba3a";
  }
  &ginger:before {
    content: "\ba3b";
  }
  &grain:before {
    content: "\ba3c";
  }
  &grape:before {
    content: "\ba3d";
  }
  &grater:before {
    content: "\ba3e";
  }
  &grill:before {
    content: "\ba3f";
  }
  &ham:before {
    content: "\ba40";
  }
  &hazelnut:before {
    content: "\ba41";
  }
  &honey:before {
    content: "\ba42";
  }
  &hop:before {
    content: "\ba43";
  }
  &hot_dog:before {
    content: "\ba44";
  }
  &ice_cream:before {
    content: "\ba45";
  }
  &jam:before {
    content: "\ba46";
  }
  &jar:before {
    content: "\ba47";
  }
  &jar_2:before {
    content: "\ba48";
  }
  &kaki:before {
    content: "\ba49";
  }
  &ketchup:before {
    content: "\ba4a";
  }
  &kitchen_mitt:before {
    content: "\ba4b";
  }
  &kiwi:before {
    content: "\ba4c";
  }
  &ladle:before {
    content: "\ba4d";
  }
  &lamb:before {
    content: "\ba4e";
  }
  &leek:before {
    content: "\ba4f";
  }
  &lemon:before {
    content: "\ba50";
  }
  &lettuce:before {
    content: "\ba51";
  }
  &lobster:before {
    content: "\ba52";
  }
  &lychee:before {
    content: "\ba53";
  }
  &maki:before {
    content: "\ba54";
  }
  &mango:before {
    content: "\ba55";
  }
  &melon:before {
    content: "\ba56";
  }
  &mixer:before {
    content: "\ba57";
  }
  &mushroom:before {
    content: "\ba58";
  }
  &mussel:before {
    content: "\ba59";
  }
  &mustard:before {
    content: "\ba5a";
  }
  &noodles:before {
    content: "\ba5b";
  }
  &octopus:before {
    content: "\ba5c";
  }
  &olives:before {
    content: "\ba5d";
  }
  &olives_oil:before {
    content: "\ba5e";
  }
  &onigiri:before {
    content: "\ba5f";
  }
  &onion:before {
    content: "\ba60";
  }
  &orange:before {
    content: "\ba61";
  }
  &pan:before {
    content: "\ba62";
  }
  &papaya:before {
    content: "\ba63";
  }
  &parsley:before {
    content: "\ba64";
  }
  &passion_fruit:before {
    content: "\ba65";
  }
  &peach:before {
    content: "\ba66";
  }
  &peanuts:before {
    content: "\ba67";
  }
  &pear:before {
    content: "\ba68";
  }
  &peas:before {
    content: "\ba69";
  }
  &pepper_grinder:before {
    content: "\ba6a";
  }
  &pepper_grinder_2:before {
    content: "\ba6b";
  }
  &pepper:before {
    content: "\ba6c";
  }
  &pet_food:before {
    content: "\ba6d";
  }
  &pie:before {
    content: "\ba6e";
  }
  &pistachio:before {
    content: "\ba6f";
  }
  &plaice:before {
    content: "\ba70";
  }
  &pomegranate:before {
    content: "\ba71";
  }
  &popcorn:before {
    content: "\ba72";
  }
  &pot:before {
    content: "\ba73";
  }
  &potato:before {
    content: "\ba74";
  }
  &pretzel:before {
    content: "\ba75";
  }
  &pudding:before {
    content: "\ba76";
  }
  &pumpkin:before {
    content: "\ba77";
  }
  &radicchio:before {
    content: "\ba78";
  }
  &raspberry:before {
    content: "\ba79";
  }
  &ribs:before {
    content: "\ba7a";
  }
  &rice:before {
    content: "\ba7b";
  }
  &rice_bowl:before {
    content: "\ba7c";
  }
  &ricotta:before {
    content: "\ba7d";
  }
  &roast_meat:before {
    content: "\ba7e";
  }
  &rolling_pin:before {
    content: "\ba7f";
  }
  &salami:before {
    content: "\ba80";
  }
  &salmon:before {
    content: "\ba81";
  }
  &salmon_slide:before {
    content: "\ba82";
  }
  &salt_pepper:before {
    content: "\ba83";
  }
  &salt_shaker:before {
    content: "\ba84";
  }
  &salt_shaker_2:before {
    content: "\ba85";
  }
  &samovar:before {
    content: "\ba86";
  }
  &sandwich:before {
    content: "\ba87";
  }
  &sausage:before {
    content: "\ba88";
  }
  &seed_oil:before {
    content: "\ba89";
  }
  &seeds:before {
    content: "\ba8a";
  }
  &soup:before {
    content: "\ba8b";
  }
  &soy_sauce:before {
    content: "\ba8c";
  }
  &spaghetti:before {
    content: "\ba8d";
  }
  &spatula:before {
    content: "\ba8e";
  }
  &spinach:before {
    content: "\ba8f";
  }
  &spinach_2:before {
    content: "\ba90";
  }
  &sprout:before {
    content: "\ba91";
  }
  &squid:before {
    content: "\ba92";
  }
  &star_fruit:before {
    content: "\ba93";
  }
  &strawberry:before {
    content: "\ba94";
  }
  &taco:before {
    content: "\ba95";
  }
  &take_away_drink:before {
    content: "\ba96";
  }
  &tentacle:before {
    content: "\ba97";
  }
  &toast:before {
    content: "\ba98";
  }
  &tomato:before {
    content: "\ba99";
  }
  &tuna:before {
    content: "\ba9a";
  }
  &turkey:before {
    content: "\ba9b";
  }
  &vinegar:before {
    content: "\ba9c";
  }
  &walnut:before {
    content: "\ba9d";
  }
  &water_melon:before {
    content: "\ba9e";
  }
  &zucchini:before {
    content: "\ba9f";
  }
  &cocktail_shaker:before {
    content: "\bb00";
  }
  &protein_bar:before {
    content: "\bb01";
  }
  &toblerone:before {
    content: "\bb02";
  }
  &bottle:before {
    content: "\bb03";
  }
  &canteen:before {
    content: "\bb47";
  }
  &pitcher:before {
    content: "\bb45";
  }
  &tetrapak:before {
    content: "\bb46";
  }
  &induction_stove:before {
    content: "\bb48";
  }
  &squeezer:before {
    content: "\bb49";
  }
  &stove:before {
    content: "\bb50";
  }
  &toaster:before {
    content: "\bb51";
  }
  &barrel:before {
    content: "\bb4a";
  }
  &caviar:before {
    content: "\bb4b";
  }
  &corkscrew:before {
    content: "\bb4c";
  }
  &foie_gras:before {
    content: "\bb4d";
  }
  &oyster:before {
    content: "\bb4e";
  }
  &saffron:before {
    content: "\bb4f";
  }
  &truffle:before {
    content: "\bb52";
  }
  &vanilla:before {
    content: "\bb53";
  }
}