.fb_additional_set_3_{
  &alembic_still:before {
    content: "\c000";
  }
  &arrival:before {
    content: "\c001";
  }
  &check_in:before {
    content: "\c002";
  }
  &check_out:before {
    content: "\c003";
  }
  &compass:before {
    content: "\c004";
  }
  &departure:before {
    content: "\c005";
  }
  &front_office:before {
    content: "\c006";
  }
  &highheeled_shoe:before {
    content: "\c007";
  }
  &laboratory:before {
    content: "\c008";
  }
  &late_check_in:before {
    content: "\c009";
  }
  &late_check_out:before {
    content: "\c00a";
  }
  &praying_hands:before {
    content: "\c00b";
  }
  &reception:before {
    content: "\c00c";
  }
  &rocking_horse:before {
    content: "\c00d";
  }
  &roulette:before {
    content: "\c00e";
  }
  &bookcase:before {
    content: "\c00f";
  }
  &door_window:before {
    content: "\c011";
  }
  &shutter:before {
    content: "\c012";
  }
  &window_roller_shutters:before {
    content: "\c013";
  }
  &city_tax:before {
    content: "\c014";
  }
  &paper:before {
    content: "\c015";
  }
  &passport:before {
    content: "\c016";
  }
  &people:before {
    content: "\c017";
  }
  &tax:before {
    content: "\c018";
  }
  &electric_plug_near_bed:before {
    content: "\c019";
  }
  &hydrant:before {
    content: "\c01a";
  }
  &smoke_detector:before {
    content: "\c01b";
  }
  &sprinkler:before {
    content: "\c01c";
  }
  &sprinkler_2:before {
    content: "\c01d";
  }
  &air_purifier:before {
    content: "\c01e";
  }
  &air_filter:before {
    content: "\c01f";
  }
  &calendar_user:before {
    content: "\c010";
  }
  &heated_floor:before {
    content: "\c02a";
  }
  &sink:before {
    content: "\c02b";
  }
  &violin:before {
    content: "\c02c";
  }
  &t_shirt:before {
    content: "\c02d";
  }
  &jacket:before {
    content: "\c02e";
  }
  &no_elevator:before {
    content: "\c02f";
  }
  &pants:before {
    content: "\c021";
  }
  &shorts:before {
    content: "\c022";
  }
  &sweater:before {
    content: "\c023";
  }
  &sweatshirt:before {
    content: "\c024";
  }
  &ancient_scroll:before {
    content: "\c025";
  }
  &ancient_scroll_2:before {
    content: "\c026";
  }
  &entrance:before {
    content: "\c027";
  }
  &entrance_2:before {
    content: "\c028";
  }
  &exit:before {
    content: "\c029";
  }
  &exit_2:before {
    content: "\c020";
  }
  &torah:before {
    content: "\c03a";
  }
}