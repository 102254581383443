.fb_beach_{
  &beach:before {
    content: "\ac00";
  }
  &beach_bag:before {
    content: "\ac01";
  }
  &beach_ball:before {
    content: "\ac02";
  }
  &beach_bar:before {
    content: "\ac03";
  }
  &beach_cabin:before {
    content: "\ac04";
  }
  &beach_chair:before {
    content: "\ac05";
  }
  &beach_direction:before {
    content: "\ac06";
  }
  &beach_dog:before {
    content: "\ac07";
  }
  &beach_rackets:before {
    content: "\ac08";
  }
  &beach_towel:before {
    content: "\ac09";
  }
  &beach_umbrella:before {
    content: "\ac0a";
  }
  &bikini:before {
    content: "\ac0b";
  }
  &buoy:before {
    content: "\ac0c";
  }
  &coconut_drink:before {
    content: "\ac0d";
  }
  &coral:before {
    content: "\ac0e";
  }
  &crab:before {
    content: "\ac0f";
  }
  &diving_board:before {
    content: "\ac10";
  }
  &dolphin:before {
    content: "\ac11";
  }
  &frisbee:before {
    content: "\ac12";
  }
  &inflatable:before {
    content: "\ac13";
  }
  &inflatable_unicorn:before {
    content: "\ac14";
  }
  &island:before {
    content: "\ac15";
  }
  &jellyfish:before {
    content: "\ac16";
  }
  &jet_ski:before {
    content: "\ac17";
  }
  &kite:before {
    content: "\ac18";
  }
  &life_jacket:before {
    content: "\ac19";
  }
  &lifeguard_tower:before {
    content: "\ac1a";
  }
  &lifeguard_tower_2:before {
    content: "\ac1b";
  }
  &lighthouse:before {
    content: "\ac1c";
  }
  &manta:before {
    content: "\ac1d";
  }
  &no_bikini:before {
    content: "\ac1e";
  }
  &pedal_boats:before {
    content: "\ac1f";
  }
  &pier:before {
    content: "\ac20";
  }
  &playground:before {
    content: "\ac21";
  }
  &sand_bucket:before {
    content: "\ac22";
  }
  &sandcastle:before {
    content: "\ac23";
  }
  &sea_water:before {
    content: "\ac24";
  }
  &seagull:before {
    content: "\ac25";
  }
  &seaside_sun:before {
    content: "\ac26";
  }
  &shark:before {
    content: "\ac27";
  }
  &standup_paddle:before {
    content: "\ac28";
  }
  &starfish:before {
    content: "\ac29";
  }
  &stilt_house:before {
    content: "\ac2a";
  }
  &sunscreen:before {
    content: "\ac2b";
  }
  &swimsuit:before {
    content: "\ac2c";
  }
  &swimsuit_men:before {
    content: "\ac2d";
  }
  &swing:before {
    content: "\ac2e";
  }
  &turtle:before {
    content: "\ac2f";
  }
  &water_slide:before {
    content: "\ac30";
  }
  &water_villa:before {
    content: "\ac31";
  }
  &whale:before {
    content: "\ac32";
  }
  &whistle:before {
    content: "\ac33";
  }

}