@font-face {
  font-family: 'fb_restaurant';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_restaurant.eot?cqezs1');
  src: url('fonts/fb_restaurant.eot?cqezs1#iefix') format('embedded-opentype'),
  url('fonts/fb_restaurant.woff2?cqezs1') format('woff2'),
  url('fonts/fb_restaurant.woff?cqezs1') format('woff'),
  url('fonts/fb_restaurant.ttf?cqezs1') format('truetype'),
  url('fonts/fb_restaurant.svg?cqezs1#fb_restaurant') format('svg');
  font-display: block;
}

[class^="fb_restaurant_"], [class*=" fb_restaurant_"] {
  font-family: 'fb_restaurant' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}