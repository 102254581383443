@font-face {
  font-family: 'fb_transport';
  font-weight: normal;
  font-style: normal;
  src:  url('fonts/fb_transport.eot?4d1zdb');
  src:  url('fonts/fb_transport.eot?4d1zdb#iefix') format('embedded-opentype'),
  url('fonts/fb_transport.woff2?4d1zdb') format('woff2'),
  url('fonts/fb_transport.woff?4d1zdb') format('woff'),
  url('fonts/fb_transport.ttf?4d1zdb') format('truetype'),
  url('fonts/fb_transport.svg?4d1zdb#fb_transport') format('svg');
  font-display: block;
}

[class^="fb_transport_"], [class*=" fb_transport_"] {
  font-family: 'fb_transport' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}