.fb_meeting_ {
  &3d_meeting:before {
    content: "\e900";
  }
  &audio_recorder:before {
    content: "\e901";
  }
  &banquet:before {
    content: "\e902";
  }
  &black_board:before {
    content: "\e903";
  }
  &blackout:before {
    content: "\e904";
  }
  &boardroom:before {
    content: "\e905";
  }
  &cabaret:before {
    content: "\e906";
  }
  &car_access:before {
    content: "\e907";
  }
  &classroom:before {
    content: "\e908";
  }
  &cocktail_2:before {
    content: "\e909";
  }
  &cocktail:before {
    content: "\e90a";
  }
  &coffee_break:before {
    content: "\e90b";
  }
  &conference_system:before {
    content: "\e90c";
  }
  &daylight:before {
    content: "\e90d";
  }
  &dimensions:before {
    content: "\e90e";
  }
  &dinner_dance:before {
    content: "\e90f";
  }
  &disco_dance:before {
    content: "\e910";
  }
  &empty_room:before {
    content: "\e911";
  }
  &floor:before {
    content: "\e912";
  }
  &gala_dinner:before {
    content: "\e913";
  }
  &height:before {
    content: "\e914";
  }
  &hollowsquare:before {
    content: "\e915";
  }
  &i_shape:before {
    content: "\e916";
  }
  &interactive_board:before {
    content: "\e917";
  }
  &internet:before {
    content: "\e918";
  }
  &laptop:before {
    content: "\e919";
  }
  &microphone:before {
    content: "\e91a";
  }
  &mobile_walls:before {
    content: "\e91b";
  }
  &o_shape:before {
    content: "\e91c";
  }
  &overhead_projector:before {
    content: "\e91d";
  }
  &paper:before {
    content: "\e91e";
  }
  &pencil:before {
    content: "\e91f";
  }
  &projector:before {
    content: "\e920";
  }
  &screen:before {
    content: "\e921";
  }
  &setup:before {
    content: "\e922";
  }
  &surface:before {
    content: "\e923";
  }
  &t_shape:before {
    content: "\e924";
  }
  &theatre:before {
    content: "\e925";
  }
  &translation_service:before {
    content: "\e926";
  }
  &u_shape:before {
    content: "\e927";
  }
  &water_bottle:before {
    content: "\e928";
  }
  &white_black_board:before {
    content: "\e929";
  }
  &white_board:before {
    content: "\e92a";
  }
  &wifi:before {
    content: "\e92b";
  }
  &runway:before {
    content: "\e9a10";
  }
  &h_shape:before {
    content: "\e92c";
  }
  &arrow_length:before {
    content: "\e92e";
  }
  &arrow_width:before {
    content: "\e92f";
  }
  &arrow_height:before {
    content: "\e92d";
  }
}