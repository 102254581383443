@font-face {
  font-family: 'fb_flora';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_flora.eot?etugip');
  src: url('fonts/fb_flora.eot?etugip#iefix') format('embedded-opentype'),
  url('fonts/fb_flora.woff2?etugip') format('woff2'),
  url('fonts/fb_flora.woff?etugip') format('woff'),
  url('fonts/fb_flora.ttf?etugip') format('truetype'),
  url('fonts/fb_flora.svg?etugip#fb_flora') format('svg');
  font-display: block;
}

[class^="fb_flora_"], [class*=" fb_flora_"] {
  font-family: 'fb_flora' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
