.fb_wellness_2_ {
  &acupuncture:before {
    content: "\ca00";
  }
  &aerobics:before {
    content: "\ca01";
  }
  &antiaging:before {
    content: "\ca02";
  }
  &bathrobe:before {
    content: "\ca03";
  }
  &body:before {
    content: "\ca04";
  }
  &cabin:before {
    content: "\ca05";
  }
  &chocolate:before {
    content: "\ca06";
  }
  &cream_tube:before {
    content: "\ca07";
  }
  &detox:before {
    content: "\ca08";
  }
  &dispenser:before {
    content: "\ca09";
  }
  &essential_oil:before {
    content: "\ca0a";
  }
  &facial_treatment:before {
    content: "\ca0b";
  }
  &foot_spa:before {
    content: "\ca0c";
  }
  &hairbrush:before {
    content: "\ca0d";
  }
  &hammam:before {
    content: "\ca0e";
  }
  &hand_cream:before {
    content: "\ca0f";
  }
  &heart_care:before {
    content: "\ca10";
  }
  &himalayan_salt:before {
    content: "\ca11";
  }
  &honey:before {
    content: "\ca12";
  }
  &hydration:before {
    content: "\ca13";
  }
  &hydromassage:before {
    content: "\ca14";
  }
  &incense:before {
    content: "\ca15";
  }
  &locker_room:before {
    content: "\ca16";
  }
  &makeup:before {
    content: "\ca17";
  }
  &manicure:before {
    content: "\ca1f";
  }
  &massage_table:before {
    content: "\ca19";
  }
  &mud_massage:before {
    content: "\ca1a";
  }
  &nails:before {
    content: "\ca1b";
  }
  &olives:before {
    content: "\ca1c";
  }
  &olives_oil:before {
    content: "\ca1d";
  }
  &onsen:before {
    content: "\ca1e";
  }
  &pedicure:before {
    content: "\ca18";
  }
  &pool:before {
    content: "\ca20";
  }
  &pool_2:before {
    content: "\ca21";
  }
  &room_fragrance:before {
    content: "\ca22";
  }
  &sakura:before {
    content: "\ca23";
  }
  &hot_bathtub:before {
    content: "\ca24";
  }
  &stone_massage:before {
    content: "\ca25";
  }
  &stones:before {
    content: "\ca26";
  }
  &thermal_stone:before {
    content: "\ca27";
  }
  &toning:before {
    content: "\ca28";
  }
  &waxing:before {
    content: "\ca29";
  }
  &yin_yang:before {
    content: "\ca2a";
  }
  &sauna_bucket:before {
    content: "\ca30";
  }
  &yoga:before {
    content: "\ca31";
  }
  &chakra:before {
    content: "\ca32";
  }
  &foot_massage:before {
    content: "\ca33";
  }
}