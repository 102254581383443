@font-face {
  font-family: 'fb_generic_set';
  font-weight: normal;
  font-style: normal;
  src:  url('fonts/fb_generic_set.eot?yzg3dx');
  src:  url('fonts/fb_generic_set.eot?yzg3dx#iefix') format('embedded-opentype'),
  url('fonts/fb_generic_set.woff2?yzg3dx') format('woff2'),
  url('fonts/fb_generic_set.woff?yzg3dx') format('woff'),
  url('fonts/fb_generic_set.ttf?yzg3dx') format('truetype'),
  url('fonts/fb_generic_set.svg?yzg3dx#fb_generic_set') format('svg');
  font-display: block;
}

[class^="fb_icon_"], [class*=" fb_icon_"] {
  font-family: 'fb_generic_set' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}