.fb_weather_ {
  &barometer:before {
    content: "\d000";
  }
  &cloud:before {
    content: "\d001";
  }
  &cloud_rain:before {
    content: "\d002";
  }
  &cloud_snow:before {
    content: "\d003";
  }
  &cloudy:before {
    content: "\d004";
  }
  &dust_storm:before {
    content: "\d005";
  }
  &first_quarter:before {
    content: "\d006";
  }
  &fog:before {
    content: "\d007";
  }
  &full_moon:before {
    content: "\d008";
  }
  &hailstorm:before {
    content: "\d009";
  }
  &haze:before {
    content: "\d00a";
  }
  &hurricane:before {
    content: "\d00b";
  }
  &last_quarter:before {
    content: "\d00c";
  }
  &lightning:before {
    content: "\d00d";
  }
  &meteorites:before {
    content: "\d00e";
  }
  &mixed_rain_hail:before {
    content: "\d00f";
  }
  &mixed_rain_snow:before {
    content: "\d010";
  }
  &moon:before {
    content: "\d011";
  }
  &moon_phases:before {
    content: "\d012";
  }
  &new_moon:before {
    content: "\d013";
  }
  &partly_cloudy_day:before {
    content: "\d014";
  }
  &partly_cloudy_night:before {
    content: "\d015";
  }
  &rain:before {
    content: "\d016";
  }
  &rainbow:before {
    content: "\d017";
  }
  &raining_frogs:before {
    content: "\d018";
  }
  &raining_meatballs:before {
    content: "\d019";
  }
  &snow:before {
    content: "\d01a";
  }
  &snow_flurries:before {
    content: "\d01b";
  }
  &snow_storm:before {
    content: "\d01c";
  }
  &star:before {
    content: "\d01d";
  }
  &stars:before {
    content: "\d01e";
  }
  &sun:before {
    content: "\d01f";
  }
  &sun_glasses:before {
    content: "\d020";
  }
  &sunrise:before {
    content: "\d021";
  }
  &sunset:before {
    content: "\d022";
  }
  &temperature_high:before {
    content: "\d023";
  }
  &temperature_low:before {
    content: "\d024";
  }
  &thermometer:before {
    content: "\d025";
  }
  &thunderstorm:before {
    content: "\d026";
  }
  &tornado:before {
    content: "\d027";
  }
  &tropical_storm:before {
    content: "\d028";
  }
  &umbrella:before {
    content: "\d029";
  }
  &waning_crescent:before {
    content: "\d02a";
  }
  &waning_gibbous:before {
    content: "\d02b";
  }
  &waxing_crescent:before {
    content: "\d02c";
  }
  &waxing_gibbous:before {
    content: "\d02d";
  }
  &wind:before {
    content: "\d02e";
  }
  &wind_arrow:before {
    content: "\d02f";
  }
  &wind_flag:before {
    content: "\d030";
  }
  &wind_flag_vintage:before {
    content: "\d031";
  }
  &wind_rose:before {
    content: "\d032";
  }
  &night_day:before {
    content: "\d033";
  }

}