.fb_events_{
  &balloon:before {
    content: "\ee00";
  }
  &balloons:before {
    content: "\ee01";
  }
  &bat:before {
    content: "\ee02";
  }
  &bats:before {
    content: "\ee03";
  }
  &befana:before {
    content: "\ee04";
  }
  &black_cat:before {
    content: "\ee05";
  }
  &bow:before {
    content: "\ee06";
  }
  &butchers_broom:before {
    content: "\ee07";
  }
  &calavera:before {
    content: "\ee08";
  }
  &candles:before {
    content: "\ee09";
  }
  &candy:before {
    content: "\ee0a";
  }
  &candy_cane:before {
    content: "\ee0b";
  }
  &carnival_mask:before {
    content: "\ee0c";
  }
  &cauldron:before {
    content: "\ee0d";
  }
  &champagne:before {
    content: "\ee0e";
  }
  &cheers_beer:before {
    content: "\ee0f";
  }
  &cheers_wine:before {
    content: "\ee10";
  }
  &chick:before {
    content: "\ee11";
  }
  &chinese_dragon:before {
    content: "\ee12";
  }
  &chinese_knot:before {
    content: "\ee13";
  }
  &chocolate_box:before {
    content: "\ee14";
  }
  &christmas_balls:before {
    content: "\ee15";
  }
  &christmas_lights:before {
    content: "\ee16";
  }
  &christmas_sock:before {
    content: "\ee17";
  }
  &christmas_tree:before {
    content: "\ee18";
  }
  &comet:before {
    content: "\ee19";
  }
  &cupid:before {
    content: "\ee1a";
  }
  &dove:before {
    content: "\ee1b";
  }
  &easter_basket:before {
    content: "\ee1c";
  }
  &easter_egg:before {
    content: "\ee1d";
  }
  &easter_rabbit:before {
    content: "\ee1e";
  }
  &festive_hat:before {
    content: "\ee1f";
  }
  &fireworks:before {
    content: "\ee20";
  }
  &fireworks_2:before {
    content: "\ee21";
  }
  &fishbone:before {
    content: "\ee22";
  }
  &ghost:before {
    content: "\ee23";
  }
  &ghost_2:before {
    content: "\ee24";
  }
  &gift:before {
    content: "\ee25";
  }
  &gingerbread_man:before {
    content: "\ee26";
  }
  &halloween_pumpkin:before {
    content: "\ee27";
  }
  &joker_hat:before {
    content: "\ee28";
  }
  &menorah:before {
    content: "\ee29";
  }
  &nativity:before {
    content: "\ee2a";
  }
  &owl:before {
    content: "\ee2b";
  }
  &pandoro:before {
    content: "\ee2c";
  }
  &panettone:before {
    content: "\ee2d";
  }
  &paper_lantern:before {
    content: "\ee2e";
  }
  &paper_lantern_2:before {
    content: "\ee2f";
  }
  &ramadan:before {
    content: "\ee30";
  }
  &reindeer:before {
    content: "\ee31";
  }
  &rudolf:before {
    content: "\ee32";
  }
  &santa_claus:before {
    content: "\ee33";
  }
  &santa_claus_hat:before {
    content: "\ee34";
  }
  &shopping_bag:before {
    content: "\ee35";
  }
  &shopping_bag_2:before {
    content: "\ee36";
  }
  &skeleton:before {
    content: "\ee37";
  }
  &snowflake:before {
    content: "\ee38";
  }
  &snowglobe:before {
    content: "\ee39";
  }
  &snowman:before {
    content: "\ee3a";
  }
  &spider_web:before {
    content: "\ee3b";
  }
  &thanksgiving_turkey:before {
    content: "\ee3c";
  }
  &unicorn:before {
    content: "\ee3d";
  }
  &witch_hat:before {
    content: "\ee3e";
  }
  &wizard_hat:before {
    content: "\ee3f";
  }
  &wreath:before {
    content: "\ee40";
  }
  &gingerbread_house:before {
    content: "\ee41";
  }

}