@font-face {
  font-family: 'fb_wellness';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_wellness.eot?etugip');
  src: url('fonts/fb_wellness.eot?etugip#iefix') format('embedded-opentype'),
  url('fonts/fb_wellness.woff2?etugip') format('woff2'),
  url('fonts/fb_wellness.woff?etugip') format('woff'),
  url('fonts/fb_wellness.ttf?etugip') format('truetype'),
  url('fonts/fb_wellness.svg?etugip#fb_wellness') format('svg');
  font-display: block;
}

[class^="fb_wellness_"], [class*=" fb_wellness_"] {
  font-family: 'fb_wellness' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}