.fb_fauna_{
  &bee:before {
    content: "\b100";
  }
  &butterfly:before {
    content: "\b101";
  }
  &ladybug:before {
    content: "\b102";
  }
  &mosquito:before {
    content: "\b103";
  }
  &tortoise:before {
    content: "\b104";
  }
  &tropical_fish:before {
    content: "\b105";
  }
  &fossil:before {
    content: "\b106";
  }
  &nautilus:before {
    content: "\b107";
  }
  &oyster:before {
    content: "\b108";
  }
  &scallop:before {
    content: "\b109";
  }
  &tropical_shell:before {
    content: "\b110";
  }
  &tropical_shell_2:before {
    content: "\b111";
  }
  &pheasant:before {
    content: "\b112";
  }
  &cat:before {
    content: "\b113";
  }
  &cockatoo:before {
    content: "\b114";
  }
  &dodo:before {
    content: "\b115";
  }
  &duck:before {
    content: "\b116";
  }
  &eagle:before {
    content: "\b11a";
  }
  &flamingo:before {
    content: "\b11b";
  }
  &goose:before {
    content: "\b11c";
  }
  &hen:before {
    content: "\b11d";
  }
  &heron:before {
    content: "\b11e";
  }
  &kiwi:before {
    content: "\b11f";
  }
  &ostrich:before {
    content: "\b10c";
  }
  &parrot:before {
    content: "\b10d";
  }
  &penguin:before {
    content: "\b10e";
  }
  &rooster:before {
    content: "\b10f";
  }
  &sparrow:before {
    content: "\b120";
  }
  &swan:before {
    content: "\b10a";
  }
  &toucan:before {
    content: "\b10b";
  }
  &bear:before {
    content: "\b117";
  }
  &dragonfly:before {
    content: "\b118";
  }
  &horse:before {
    content: "\b119";
  }
  &horse_head:before {
    content: "\b121";
  }
  &lion:before {
    content: "\b122";
  }
  &pig:before {
    content: "\b123";
  }
  &rabbit:before {
    content: "\b124";
  }
  &swallow:before {
    content: "\b125";
  }
  &buffalo:before {
    content: "\b126";
  }
  &elephant:before {
    content: "\b127";
  }
  &giraffe:before {
    content: "\b128";
  }
  &hippo:before {
    content: "\b129";
  }
  &mule:before {
    content: "\b12a";
  }
  &polecat:before {
    content: "\b12b";
  }
  &rhino:before {
    content: "\b12c";
  }
  &zebra:before {
    content: "\b12d";
  }
  &antelope:before {
    content: "\b137";
  }
  &antelope_head:before {
    content: "\b136";
  }
  &camel:before {
    content: "\b135";
  }
  &doe:before {
    content: "\b134";
  }
  &dromedary:before {
    content: "\b133";
  }
  &dugong:before {
    content: "\b132";
  }
  &goat_head:before {
    content: "\b131";
  }
  &hind:before {
    content: "\b130";
  }
  &mongolian_camel:before {
    content: "\b12f";
  }
  &oryx:before {
    content: "\b12e";
  }
}
