.fb_safe_clean_ {
  &air_recirculation:before {
    content: "\a100";
  }

  &air_recirculation_2:before {
    content: "\a101";
  }

  &alert_signal:before {
    content: "\a102";
  }

  &alert_signal_virus:before {
    content: "\a103";
  }

  &bacteria:before {
    content: "\a104";
  }

  &bacteria_free:before {
    content: "\a105";
  }

  &broom:before {
    content: "\a106";
  }

  &brush:before {
    content: "\a107";
  }

  &bubbles:before {
    content: "\a108";
  }

  &card_pass:before {
    content: "\a109";
  }

  &cleaning:before {
    content: "\a10a";
  }

  &cleaning_2:before {
    content: "\a10b";
  }

  &cleaning_3:before {
    content: "\a10c";
  }

  &covid_pass:before {
    content: "\a10d";
  }

  &doctor:before {
    content: "\a10e";
  }

  &first_aid:before {
    content: "\a10f";
  }

  &gloves:before {
    content: "\a110";
  }

  &hand_cleaning:before {
    content: "\a111";
  }

  &hand_safe:before {
    content: "\a112";
  }

  &hospital:before {
    content: "\a113";
  }

  &insect:before {
    content: "\a114";
  }

  &insect_free:before {
    content: "\a115";
  }

  &laundry:before {
    content: "\a116";
  }

  &laundry_soap:before {
    content: "\a117";
  }

  &liquid_soap:before {
    content: "\a118";
  }

  &mask:before {
    content: "\a119";
  }

  &pills:before {
    content: "\a11a";
  }

  &sanitizing_gel:before {
    content: "\a11b";
  }

  &shield:before {
    content: "\a11c";
  }

  &soap:before {
    content: "\a11d";
  }

  &soap_dispenser:before {
    content: "\a11e";
  }

  &social_distancing:before {
    content: "\a11f";
  }

  &sponge:before {
    content: "\a120";
  }

  &spray:before {
    content: "\a121";
  }

  &syringe:before {
    content: "\a122";
  }

  &thermometer:before {
    content: "\a123";
  }

  &thermometer_2:before {
    content: "\a124";
  }

  &towels:before {
    content: "\a125";
  }

  &vacuum:before {
    content: "\a126";
  }

  &virus:before {
    content: "\a127";
  }

  &virus_2:before {
    content: "\a128";
  }

  &virus_free:before {
    content: "\a129";
  }

}
