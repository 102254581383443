.fb_places_ {
  &amphitheater:before {
    content: "\ea00";
  }
  &camping:before {
    content: "\ea01";
  }
  &casino:before {
    content: "\ea02";
  }
  &church:before {
    content: "\ea03";
  }
  &city:before {
    content: "\ea04";
  }
  &city_center:before {
    content: "\ea05";
  }
  &disco:before {
    content: "\ea06";
  }
  &earth:before {
    content: "\ea07";
  }
  &excursions:before {
    content: "\ea08";
  }
  &factory:before {
    content: "\ea09";
  }
  &fairs:before {
    content: "\ea0a";
  }
  &flag:before {
    content: "\ea0b";
  }
  &garden:before {
    content: "\ea0c";
  }
  &gps:before {
    content: "\ea0d";
  }
  &harbor:before {
    content: "\ea0e";
  }
  &home:before {
    content: "\ea0f";
  }
  &hospital:before {
    content: "\ea10";
  }
  &hotel:before {
    content: "\ea11";
  }
  &info_point:before {
    content: "\ea12";
  }
  &island:before {
    content: "\ea13";
  }
  &lake:before {
    content: "\ea14";
  }
  &lighthouse:before {
    content: "\ea15";
  }
  &location:before {
    content: "\ea16";
  }
  &monuments:before {
    content: "\ea17";
  }
  &mountain:before {
    content: "\ea18";
  }
  &museum:before {
    content: "\ea19";
  }
  &park:before {
    content: "\ea1a";
  }
  &roof_garden:before {
    content: "\ea1b";
  }
  &sea_water:before {
    content: "\ea1c";
  }
  &sightseeing:before {
    content: "\ea1d";
  }
  &theater:before {
    content: "\ea1e";
  }
  &village:before {
    content: "\ea1f";
  }
  &map:before {
    content: "\eaa1";
  }
  &map_marker:before {
    content: "\eaa2";
  }
  &treasure_map:before {
    content: "\eaa3";
  }
  &aquarium:before {
    content: "\eaa5";
  }
  &directional_sign:before {
    content: "\eaa6";
  }
  &observatory:before {
    content: "\eaa7";
  }
  &stadium:before {
    content: "\eaa8";
  }
  &zoo:before {
    content: "\eaa9";
  }
  &amusement_park:before {
    content: "\eaa0";
  }
  &circus:before {
    content: "\eab0";
  }

}
