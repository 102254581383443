@font-face {
  font-family: 'fb_places';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_places.eot?3oryhw');
  src: url('fonts/fb_places.eot?3oryhw#iefix') format('embedded-opentype'),
  url('fonts/fb_places.woff2?3oryhw') format('woff2'),
  url('fonts/fb_places.woff?3oryhw') format('woff'),
  url('fonts/fb_places.ttf?3oryhw') format('truetype'),
  url('fonts/fb_places.svg?3oryhw#fb_places') format('svg');
  font-display: block;
}

[class^="fb_places_"], [class*=" fb_places_"] {
  font-family: 'fb_places' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}