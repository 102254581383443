@font-face {
  font-family: 'fb_events';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_events.eot?kvj6tx');
  src: url('fonts/fb_events.eot?kvj6tx#iefix') format('embedded-opentype'),
  url('fonts/fb_events.woff2?kvj6tx') format('woff2'),
  url('fonts/fb_events.woff?kvj6tx') format('woff'),
  url('fonts/fb_events.ttf?kvj6tx') format('truetype'),
  url('fonts/fb_events.svg?kvj6tx#fb_events') format('svg');
  font-display: block;
}

[class^="fb_events_"], [class*=" fb_events_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fb_events' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}