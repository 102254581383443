.fb_media_player_{

  &play:before {
    content: "\bc00";
  }
  &amazon_alexa:before {
    content: "\bc01";
  }
  &backward:before {
    content: "\bc02";
  }
  &controls:before {
    content: "\bc03";
  }
  &dazn:before {
    content: "\bc04";
  }
  &discovery_plus:before {
    content: "\bc05";
  }
  &disney_plus:before {
    content: "\bc06";
  }
  &earphones:before {
    content: "\bc07";
  }
  &earphones_off:before {
    content: "\bc08";
  }
  &eject:before {
    content: "\bc09";
  }
  &favorite:before {
    content: "\bc0a";
  }
  &forward:before {
    content: "\bc0b";
  }
  &fullscreen:before {
    content: "\bc0c";
  }
  &google_home:before {
    content: "\bc0d";
  }
  &google_home_2:before {
    content: "\bc0e";
  }
  &gramophone:before {
    content: "\bc0f";
  }
  &headphone:before {
    content: "\bc10";
  }
  &headphone_off:before {
    content: "\bc11";
  }
  &home_theatre:before {
    content: "\bc12";
  }
  &ipod:before {
    content: "\bc13";
  }
  &microphone:before {
    content: "\bc14";
  }
  &microphone_off:before {
    content: "\bc15";
  }
  &miniplayer:before {
    content: "\bc16";
  }
  &mixer_audio:before {
    content: "\bc17";
  }
  &monitor_nearfield:before {
    content: "\bc18";
  }
  &musical_note:before {
    content: "\bc19";
  }
  &musical_note_2:before {
    content: "\bc1a";
  }
  &netflix:before {
    content: "\bc1b";
  }
  &netflix_2:before {
    content: "\bc1c";
  }
  &next:before {
    content: "\bc1d";
  }
  &obs:before {
    content: "\bc1e";
  }
  &pause:before {
    content: "\bc1f";
  }
  &playlist:before {
    content: "\bc20";
  }
  &previous:before {
    content: "\bc21";
  }
  &prime_video:before {
    content: "\bc22";
  }
  &record:before {
    content: "\bc23";
  }
  &record_player:before {
    content: "\bc24";
  }
  &replay:before {
    content: "\bc25";
  }
  &settings:before {
    content: "\bc26";
  }
  &shuffle:before {
    content: "\bc27";
  }
  &sleep_timer:before {
    content: "\bc28";
  }
  &spotify:before {
    content: "\bc29";
  }
  &spotlight:before {
    content: "\bc2a";
  }
  &step_backward:before {
    content: "\bc2b";
  }
  &step_forward:before {
    content: "\bc2c";
  }
  &stop:before {
    content: "\bc2d";
  }
  &subtitles:before {
    content: "\bc2e";
  }
  &subtitles_2:before {
    content: "\bc2f";
  }
  &Tavola-disegno-1:before {
    content: "\bc30";
  }
  &twitch:before {
    content: "\bc31";
  }
  &twitch_2:before {
    content: "\bc32";
  }
  &volume_ban:before {
    content: "\bc33";
  }
  &volume_down:before {
    content: "\bc34";
  }
  &volume_minus:before {
    content: "\bc35";
  }
  &volume_mute:before {
    content: "\bc36";
  }
  &volume_off:before {
    content: "\bc37";
  }
  &volume_plus:before {
    content: "\bc38";
  }
  &volume_up:before {
    content: "\bc39";
  }
  &webcam:before {
    content: "\bc3a";
  }
  &webcam_2:before {
    content: "\bc3b";
  }
  &webcam_off:before {
    content: "\bc3c";
  }
  &dvd:before {
    content: "\bc3d";
  }
  &dvd_player:before {
    content: "\bc3e";
  }
  &musicassette:before {
    content: "\bc40";
  }
  &vhs:before {
    content: "\bc41";
  }
  &vinyl:before {
    content: "\bc42";
  }
}