@font-face {
  font-family: 'fb_allergens';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_allergens.eot?cr4vbs');
  src: url('fonts/fb_allergens.eot?cr4vbs#iefix') format('embedded-opentype'),
  url('fonts/fb_allergens.woff2?cr4vbs') format('woff2'),
  url('fonts/fb_allergens.woff?cr4vbs') format('woff'),
  url('fonts/fb_allergens.ttf?cr4vbs') format('truetype'),
  url('fonts/fb_allergens.svg?cr4vbs#fb_allergens') format('svg');
  font-display: block;
}

[class^="fb_allergens_"], [class*=" fb_allergens_"] {
  font-family: 'fb_allergens' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}