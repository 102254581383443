.fb_currency_ {
  &baht_thailandese:before {
    content: "\eb10";
  }
  &bitcoin:before {
    content: "\eb11";
  }
  &british_pound:before {
    content: "\eb12";
  }
  &currency_exchange:before {
    content: "\eb13";
  }
  &dollar:before {
    content: "\eb14";
  }
  &euro:before {
    content: "\eb15";
  }
  &yen:before {
    content: "\eb16";
  }
  &banknotes:before {
    content: "\eb17";
  }
  &coins:before {
    content: "\eb18";
  }
  &coins_dollars:before {
    content: "\eb19";
  }
  &coins_euro:before {
    content: "\eb1a";
  }
  &hand_banknote:before {
    content: "\eb1b";
  }
  &hand_coins:before {
    content: "\eb1c";
  }
  &money_bag:before {
    content: "\eb1d";
  }
  &money_transfer:before {
    content: "\eb1e";
  }
  &not_refundable:before {
    content: "\eb20";
  }
  &treasure_chest:before {
    content: "\eb21";
  }
  &wallet:before {
    content: "\eb1f";
  }

}
