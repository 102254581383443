@font-face {
  font-family: 'fb_socials';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_socials.eot?bu32fm');
  src: url('fonts/fb_socials.eot?bu32fm#iefix') format('embedded-opentype'),
  url('fonts/fb_socials.woff2?bu32fm') format('woff2'),
  url('fonts/fb_socials.woff?bu32fm') format('woff'),
  url('fonts/fb_socials.ttf?bu32fm') format('truetype'),
  url('fonts/fb_socials.svg?bu32fm#icomoon') format('svg');
  font-display: block;
}

[class^="fb_social_"], [class*=" fb_social_"] {
  font-family: 'fb_socials' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}