.fb_flora_{
  &crane_flower:before {
    content: "\a400";
  }
  &flowering_branch:before {
    content: "\a401";
  }
  &hibiscus:before {
    content: "\a402";
  }
  &maple_leaf:before {
    content: "\a403";
  }
  &monstera_leaf:before {
    content: "\a404";
  }
  &orchid:before {
    content: "\a405";
  }
  &rose:before {
    content: "\a406";
  }
  &seasons:before {
    content: "\a407";
  }
  &tropical_leaf:before {
    content: "\a408";
  }
  &calla:before {
    content: "\a413";
  }
  &dandelion:before {
    content: "\a409";
  }
  &potted_plant:before {
    content: "\a410";
  }
  &sunflower:before {
    content: "\a411";
  }
  &water_lily:before {
    content: "\a412";
  }
  &carnivorous_plant:before {
    content: "\a414";
  }
  &venus_flytrap:before {
    content: "\a41a";
  }
  &cyclamen:before {
    content: "\a415";
  }
  &daisy:before {
    content: "\a416";
  }
  &edelweiss:before {
    content: "\a417";
  }
  &log:before {
    content: "\a418";
  }
  &prickly_pear:before {
    content: "\a419";
  }
  &ginkgo_leaf:before{
    content: "\a41b";
  }
  &ginkgo_leaf_2:before{
    content: "\a41c";
  }
  &mimosa:before {
    content: "\a41d";
  }
  &pansy:before {
    content: "\a41e";
  }
  &rowan:before {
    content: "\a41f";
  }
  &valley_lily:before {
    content: "\a42a";
  }
  &winter_jasmine:before {
    content: "\a42b";
  }
}
