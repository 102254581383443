@font-face {
  font-family: 'fb_wellness_2';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_wellness_2.eot?bk83zz');
  src: url('fonts/fb_wellness_2.eot?bk83zz#iefix') format('embedded-opentype'),
  url('fonts/fb_wellness_2.woff2?bk83zz') format('woff2'),
  url('fonts/fb_wellness_2.woff?bk83zz') format('woff'),
  url('fonts/fb_wellness_2.ttf?bk83zz') format('truetype'),
  url('fonts/fb_wellness_2.svg?bk83zz#fb_wellness_2') format('svg');
  font-display: block;
}

[class^="fb_wellness_2_"], [class*=" fb_wellness_2_"] {
  font-family: 'fb_wellness_2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}