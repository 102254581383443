@font-face {
  font-family: 'fb_room_icons';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_room_icons.eot?s4y4nh');
  src: url('fonts/fb_room_icons.eot?s4y4nh#iefix') format('embedded-opentype'),
  url('fonts/fb_room_icons.woff2?s4y4nh') format('woff2'),
  url('fonts/fb_room_icons.woff?s4y4nh') format('woff'),
  url('fonts/fb_room_icons.ttf?s4y4nh') format('truetype'),
  url('fonts/fb_room_icons.svg?s4y4nh#fb_room_icons') format('svg');
  font-display: block;
}

[class^="fb_room_"], [class*=" fb_room_"] {
  font-family: 'fb_room_icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}