@font-face {
  font-family: 'fb_room_2';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_room_2.eot?s4y4nh');
  src: url('fonts/fb_room_2.eot?s4y4nh#iefix') format('embedded-opentype'),
  url('fonts/fb_room_2.woff2?s4y4nh') format('woff2'),
  url('fonts/fb_room_2.woff?s4y4nh') format('woff'),
  url('fonts/fb_room_2.ttf?s4y4nh') format('truetype'),
  url('fonts/fb_room_2.svg?s4y4nh#fb_room_2') format('svg');
  font-display: block;
}

[class^="fb_room_2_"], [class*=" fb_room_2_"] {
  font-family: 'fb_room_2' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}