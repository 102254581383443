@font-face {
  font-family: 'fb_bathroom_icons';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_bathroom_icons.eot?1ij64g');
  src: url('fonts/fb_bathroom_icons.eot?1ij64g#iefix') format('embedded-opentype'),
  url('fonts/fb_bathroom_icons.woff2?1ij64g') format('woff2'),
  url('fonts/fb_bathroom_icons.woff?1ij64g') format('woff'),
  url('fonts/fb_bathroom_icons.ttf?1ij64g') format('truetype'),
  url('fonts/fb_bathroom_icons.svg?1ij64g#fb_bathroom_icons') format('svg');
  font-display: block;
}

[class^="fb_bathroom_"], [class*=" fb_bathroom_"] {
  font-family: 'fb_bathroom_icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}