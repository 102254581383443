.fb_additional_set_2_ {
  &wedding_arch:before {
    content: "\a368";
  }
  &accessibility:before {
    content: "\a301";
  }
  &approved_certificate:before {
    content: "\a302";
  }
  &approved_stamp:before {
    content: "\a303";
  }
  &armor_helmet:before {
    content: "\a304";
  }
  &barrier:before {
    content: "\a305";
  }
  &beard:before {
    content: "\a306";
  }
  &bed:before {
    content: "\a307";
  }
  &bezier:before {
    content: "\a308";
  }
  &bone:before {
    content: "\a309";
  }
  &book:before {
    content: "\a30a";
  }
  &bouquet:before {
    content: "\a30b";
  }
  &braille:before {
    content: "\a30c";
  }
  &brainstorming:before {
    content: "\a30d";
  }
  &brochure:before {
    content: "\a30e";
  }
  &bulb:before {
    content: "\a30f";
  }
  &bulldozer:before {
    content: "\a310";
  }
  &cactus:before {
    content: "\a311";
  }
  &certificate:before {
    content: "\a312";
  }
  &cloud_download:before {
    content: "\a313";
  }
  &cloud_server:before {
    content: "\a314";
  }
  &cloud_upload:before {
    content: "\a315";
  }
  &clown:before {
    content: "\a316";
  }
  &color_picker:before {
    content: "\a317";
  }
  &confusion:before {
    content: "\a318";
  }
  &contrast:before {
    content: "\a319";
  }
  &crystal:before {
    content: "\a31a";
  }
  &denied_certificate:before {
    content: "\a31b";
  }
  &denied_stamp:before {
    content: "\a31c";
  }
  &dinosaur:before {
    content: "\a31d";
  }
  &disabled_person:before {
    content: "\a31e";
  }
  &disabled_person_2:before {
    content: "\a31f";
  }
  &dissociative_identity_disorder:before {
    content: "\a320";
  }
  &dog:before {
    content: "\a321";
  }
  &dog_balloon:before {
    content: "\a322";
  }
  &dyslexia:before {
    content: "\a323";
  }
  &ear:before {
    content: "\a324";
  }
  &ear_deaf:before {
    content: "\a325";
  }
  &ear_deaf_2:before {
    content: "\a326";
  }
  &edit_bezier:before {
    content: "\a327";
  }
  &edit_text:before {
    content: "\a328";
  }
  &elder_people:before {
    content: "\a329";
  }
  &elder_person:before {
    content: "\a32a";
  }
  &envelope:before {
    content: "\a32b";
  }
  &envelope_letter:before {
    content: "\a32c";
  }
  &eye:before {
    content: "\a32d";
  }
  &eye_blind:before {
    content: "\a32e";
  }
  &eye_blind_2:before {
    content: "\a32f";
  }
  &eye_low_vision:before {
    content: "\a330";
  }
  &eyeglasses:before {
    content: "\a331";
  }
  &eyeglasses_2:before {
    content: "\a332";
  }
  &feather:before {
    content: "\a333";
  }
  &fingerprint:before {
    content: "\a334";
  }
  &fish_tank:before {
    content: "\a335";
  }
  &fountain_pen:before {
    content: "\a336";
  }
  &fountain_pen_2:before {
    content: "\a337";
  }
  &gavel:before {
    content: "\a338";
  }
  &gem:before {
    content: "\a339";
  }
  &gladiator_helmet:before {
    content: "\a33a";
  }
  &grandma:before {
    content: "\a33b";
  }
  &grandpa:before {
    content: "\a33c";
  }
  &hand_cart:before {
    content: "\a33d";
  }
  &handcuffs:before {
    content: "\a33e";
  }
  &hard_hat:before {
    content: "\a33f";
  }
  &head:before {
    content: "\a340";
  }
  &head_alert:before {
    content: "\a341";
  }
  &head_love:before {
    content: "\a342";
  }
  &head_question:before {
    content: "\a343";
  }
  &highlighter:before {
    content: "\a344";
  }
  &hookah:before {
    content: "\a345";
  }
  &idea:before {
    content: "\a346";
  }
  &inkwell:before {
    content: "\a347";
  }
  &laurel_crown:before {
    content: "\a348";
  }
  &man_woman:before {
    content: "\a349";
  }
  &mind:before {
    content: "\a34a";
  }
  &moustache:before {
    content: "\a34b";
  }
  &moustache_2:before {
    content: "\a34c";
  }
  &mouth:before {
    content: "\a34d";
  }
  &mouth_mute:before {
    content: "\a34e";
  }
  &mouth_mute_2:before {
    content: "\a34f";
  }
  &noose:before {
    content: "\a350";
  }
  &open_envelope:before {
    content: "\a351";
  }
  &paint_brush:before {
    content: "\a352";
  }
  &paint_roller:before {
    content: "\a353";
  }
  &palette:before {
    content: "\a354";
  }
  &peacock_feather:before {
    content: "\a355";
  }
  &pencil:before {
    content: "\a356";
  }
  &pencil_sharpener:before {
    content: "\a357";
  }
  &pipe:before {
    content: "\a358";
  }
  &pipe_no_smoking:before {
    content: "\a359";
  }
  &postage_stamp:before {
    content: "\a35a";
  }
  &rescue_dog:before {
    content: "\a35b";
  }
  &rubber:before {
    content: "\a35c";
  }
  &scales:before {
    content: "\a35d";
  }
  &security_camera:before {
    content: "\a35e";
  }
  &sign_language:before {
    content: "\a35f";
  }
  &single_bed:before {
    content: "\a360";
  }
  &spy:before {
    content: "\a361";
  }
  &stamp:before {
    content: "\a362";
  }
  &sustainability:before {
    content: "\a363";
  }
  &thumbtack:before {
    content: "\a364";
  }
  &traffic_cone:before {
    content: "\a365";
  }
  &tulip:before {
    content: "\a366";
  }
  &viking_helmet:before {
    content: "\a367";
  }
  &bomb:before {
    content: "\a369";
  }
  &coding:before {
    content: "\a370";
  }
  &error_404:before {
    content: "\a371";
  }
  &bonsai:before {
    content: "\a372";
  }
  &butler:before {
    content: "\a373";
  }
  &card_suits:before {
    content: "\a374";
  }
  &chess_bishop:before {
    content: "\a375";
  }
  &chess_horse:before {
    content: "\a376";
  }
  &chess_king:before {
    content: "\a377";
  }
  &chess_pawn:before {
    content: "\a378";
  }
  &chess_queen:before {
    content: "\a379";
  }
  &chess_tower:before {
    content: "\a37a";
  }
  &droplets:before {
    content: "\a37b";
  }
  &fan:before {
    content: "\a37c";
  }
  &ceiling_fan:before {
    content: "\a37d";
  }
  &folding_fan:before {
    content: "\a37e";
  }
  &game_dice:before {
    content: "\a37f";
  }
  &horseshoe:before {
    content: "\a380";
  }
  &image_resize:before {
    content: "\a381";
  }
  &magnet:before {
    content: "\a382";
  }
  &microwave:before {
    content: "\a383";
  }
  &mouse:before {
    content: "\a384";
  }
  &oven:before {
    content: "\a385";
  }
  &piano:before {
    content: "\a386";
  }
  &fourleaf_clover:before {
    content: "\a387";
  }
  &resize_collapsed:before {
    content: "\a388";
  }
  &resize_expand:before {
    content: "\a389";
  }
  &ruler:before {
    content: "\a38a";
  }
  &set_square:before {
    content: "\a38b";
  }
  &ruler_width:before {
    content: "\a38e";
  }
  &ruler_height:before {
    content: "\a38c";
  }
  &ruler_length:before {
    content: "\a38d";
  }
}