@font-face {
  font-family: 'fb_safe_clean';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_safe_clean.eot?etugip');
  src: url('fonts/fb_safe_clean.eot?etugip#iefix') format('embedded-opentype'),
  url('fonts/fb_safe_clean.woff2?etugip') format('woff2'),
  url('fonts/fb_safe_clean.woff?etugip') format('woff'),
  url('fonts/fb_safe_clean.ttf?etugip') format('truetype'),
  url('fonts/fb_safe_clean.svg?etugip#fb_safe_clean') format('svg');
  font-display: block;
}

[class^="fb_safe_clean_"], [class*=" fb_safe_clean_"] {
  font-family: 'fb_safe_clean' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}