.fb_sport_ {
  &archery:before {
    content: "\e7c0";
  }
  &badminton:before {
    content: "\e7c1";
  }
  &baseball:before {
    content: "\e7c2";
  }
  &basket:before {
    content: "\e7c3";
  }
  &beachvolley:before {
    content: "\e7c4";
  }
  &bike:before {
    content: "\e7c5";
  }
  &bike_rental:before {
    content: "\e7c6";
  }
  &bird_watching:before {
    content: "\e7c7";
  }
  &bowling:before {
    content: "\e7c8";
  }
  &bowls:before {
    content: "\e7c9";
  }
  &canoe:before {
    content: "\e7ca";
  }
  &diving:before {
    content: "\e7cb";
  }
  &fishing:before {
    content: "\e7cc";
  }
  &football:before {
    content: "\e7cd";
  }
  &golf:before {
    content: "\e7ce";
  }
  &golf_ball:before {
    content: "\e7cf";
  }
  &hang_glider:before {
    content: "\e7d0";
  }
  &hockey:before {
    content: "\e7d1";
  }
  &horse_riding:before {
    content: "\e7d2";
  }
  &hot_air_balloon:before {
    content: "\e7d3";
  }
  &ice_skate:before {
    content: "\e7d4";
  }
  &jogging:before {
    content: "\e7d5";
  }
  &kayak:before {
    content: "\e7d6";
  }
  &multipurpose_sports_field:before {
    content: "\e7d7";
  }
  &parachuting:before {
    content: "\e7d8";
  }
  &parasailing:before {
    content: "\e7d9";
  }
  &ping_pong:before {
    content: "\e7da";
  }
  &rugby:before {
    content: "\e7db";
  }
  &skate:before {
    content: "\e7dc";
  }
  &ski:before {
    content: "\e7dd";
  }
  &ski_storage:before {
    content: "\e7de";
  }
  &ski_storage_2:before {
    content: "\e7df";
  }
  &snowboard:before {
    content: "\e7e0";
  }
  &soccer:before {
    content: "\e7e1";
  }
  &soccer_field:before {
    content: "\e7e2";
  }
  &squash:before {
    content: "\e7e3";
  }
  &surf:before {
    content: "\e7e4";
  }
  &tennis:before {
    content: "\e7e5";
  }
  &volleyball:before {
    content: "\e7e6";
  }
  &billiards:before {
    content: "\e7e7";
  }
  &water_ski:before {
    content: "\e7e8";
  }
  &windsurf:before {
    content: "\e7e9";
  }
  &bike_helmet:before {
    content: "\a7a1";
  }
  &downhill_bike:before {
    content: "\a7a2";
  }
  &ebike:before {
    content: "\e7a3";
  }
  &ebike_2:before {
    content: "\e7a4";
  }
  &fat_bike:before {
    content: "\e7a5";
  }
  &downhill_helmet:before {
    content: "\e7a6";
  }
  &mountain_bike:before {
    content: "\e7a7";
  }
  &road_bike:before {
    content: "\e7a8";
  }
  &scooter:before {
    content: "\e7a9";
  }
  &skateboard:before {
    content: "\e7aa";
  }
  &stationary_bike:before {
    content: "\e7a1";
  }
  &tapis_roulant:before {
    content: "\e7a2";
  }
  &bow:before {
    content: "\e7b5";
  }
  &boxing:before {
    content: "\e7b4";
  }
  &crossbow:before {
    content: "\e7b3";
  }
  &deep_sea_diver:before {
    content: "\e7b2";
  }
  &jumping_rope:before {
    content: "\e7b1";
  }
  &padel:before {
    content: "\e7b0";
  }
  &scuba_diving:before {
    content: "\e7a0";
  }
  &olympic_games:before {
    content: "\e7b6";
  }
  &olympic_torch:before {
    content: "\e7b7";
  }
  &podium:before {
    content: "\e7b8";
  }
}