.fb_social_ {
  &blogger:before {
    content: "\f901";
  }
  &dropbox:before {
    content: "\f902";
  }
  &facebook_1:before {
    content: "\f903";
  }
  &facebook_2:before {
    content: "\f904";
  }
  &feed:before {
    content: "\f905";
  }
  &flickr:before {
    content: "\f906";
  }
  &foursquare:before {
    content: "\f907";
  }
  &google_drive:before {
    content: "\f908";
  }
  &googleplus:before {
    content: "\f909";
  }
  &instagram:before {
    content: "\f90a";
  }
  &line:before {
    content: "\f90b";
  }
  &linkedin:before {
    content: "\f90c";
  }
  &picasa:before {
    content: "\f90d";
  }
  &pinterest:before {
    content: "\f90e";
  }
  &pinterest_2:before {
    content: "\f900";
  }
  &share:before {
    content: "\f90f";
  }
  &snapchat:before {
    content: "\f910";
  }
  &twitter:before {
    content: "\f911";
  }
  &vimeo:before {
    content: "\f912";
  }
  &wechat:before {
    content: "\f913";
  }
  &whatsapp:before {
    content: "\f914";
  }
  &youtube:before {
    content: "\f915";
  }
  &bookingcom:before {
    content: "\f920";
  }
  &tripadvisor:before {
    content: "\f919";
  }
  &vkontakte:before {
    content: "\f918";
  }
  &vkontakte_square:before {
    content: "\f917";
  }
  &weibo:before {
    content: "\f916";
  }
  &tiktok:before {
    content: "\f921";
  }
  &spotify:before {
    content: "\f922";
  }
  &telegram:before {
    content: "\f923";
  }
  &whatsapp_2:before {
    content: "\f924";
  }
  &instagram_threads:before {
    content: "\f925";
  }
  &twitter_new:before {
    content: "\f926";
  }
}
