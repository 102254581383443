.fb_wellness_{
  &in_door_swimming_pool:before {
    content: "\e1e0";
  }
  &massage:before {
    content: "\e1e1";
  }
  &out_door_swimming_pool:before {
    content: "\e1e2";
  }
  &pool:before {
    content: "\e1e3";
  }
  &pool_towel:before {
    content: "\e1e4";
  }
  &sauna:before {
    content: "\e1e5";
  }
  &solarium:before {
    content: "\e1e6";
  }
  &support_lifebelt:before {
    content: "\e1e7";
  }
  &tanning:before {
    content: "\e1e8";
  }
  &thermae_spa:before {
    content: "\e1e9";
  }
  &bamboo:before {
    content: "\e1ea";
  }
  &lotus:before {
    content: "\e1eb";
  }
  &thermae_spa_2:before {
    content: "\e1ec";
  }
}