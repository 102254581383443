@font-face {
  font-family: 'fb_currencies';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/fb_currencies.eot?sjmqha');
  src: url('fonts/fb_currencies.eot?sjmqha#iefix') format('embedded-opentype'),
  url('fonts/fb_currencies.woff2?sjmqha') format('woff2'),
  url('fonts/fb_currencies.woff?sjmqha') format('woff'),
  url('fonts/fb_currencies.ttf?sjmqha') format('truetype'),
  url('fonts/fb_currencies.svg?sjmqha#fb_currencies') format('svg');
  font-display: block;
}

[class^="fb_currency_"], [class*=" fb_currency_"] {
  font-family: 'fb_currencies' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}